import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import {
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Button,
    Divider,
    IconButton,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
    Chip
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PatientLoginForm from "./PatientLoginForm";

import EpisodeReportButton from "./EpisodeReportButton";
import EpisodeCardItem from "./EpisodeCardItem";



import api from '../api.js';
import PatientConditionList from "./PatientConditionList.js";
import EpisodeStatus from "./EpisodeStatus";


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    gridList: {
        overflow: 'auto',
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function EpisodeGallery() {
    const classes = useStyles();
    let history = useHistory();

    const [session, setSession] = useState({});
    const [episodeList, setEpisodeList] = useState([]);

    const getData = async () => {
        try {
            let session = JSON.parse(localStorage.getItem("session") || '{}');
            setSession(session);

            if (!session.patient) { throw new Error('Session no tiene información de paciente') }
            let episodeList = await api.get('episode', {
                params: {
                    where: {
                        referral: session.patient.id,
                        patient: { '!': session.patient.id }
                    }
                }
            }) || [];
            setEpisodeList(episodeList);
        } catch (err) { console.log(err) }
    }

    useEffect(() => {
        getData();
    }, []);

    const [dialogPatientSelector, setDialogPatientSelector] = useState(false);

    const onSubmitPatientSelectorForm = ({ state }) => {
        history.push('/home/episodio-covid/nuevo/' + state);
        setDialogPatientSelector(false);
    }

    return (
        <Box position="relative">

            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <Typography variant="subtitle1" style={{
                    display: 'flex',
                    flex: 1
                }}>
                    <Box fontWeight="fontWeightLight">
                        Casos cercanos reportados
                    </Box>
                </Typography>
                {
                    episodeList.length > 0 &&
                    <IconButton color="primary" onClick={() => setDialogPatientSelector(true)}>
                        <AddBoxIcon />
                    </IconButton>
                }
            </Box>

            {
                episodeList.length == 0 &&
                <Card style={{
                    height: 225,
                    marginRight: 8,
                    padding: 8,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Typography variant="subtitle2" component="h2">
                        <Box fontWeight="fontWeightLight" textAlign="center">
                            ¿Necesitas reportar el caso de un familiar o contacto cercano?
                        </Box>
                    </Typography>
                    < br />

                    <Button variant="outlined" color="primary" onClick={() => setDialogPatientSelector(true)} className={classes.fab}>
                        <AddBoxIcon className={classes.extendedIcon} />
                        Reportar un caso cercano
                    </Button>
                </Card>
            }

            <GridList className={classes.gridList} cols={episodeList.length > 1 ? 1.1 : 1}>
                {episodeList.map(episode =>
                    <GridListTile key={episode.id} style={{ height: 230 }}>
                        <EpisodeCardItem episode={episode}></EpisodeCardItem>
                    </GridListTile>
                )}
            </GridList>

            <Dialog
                open={dialogPatientSelector}
                aria-labelledby="alert-dialog-title"
                maxWidth="sm"
                fullWidth={true}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <Box display="flex">
                        <Typography
                            variant="h6" component="h2" style={{ flex: 1 }}
                        >
                            Indicar paciente
                        </Typography>
                        <Button small onClick={() => setDialogPatientSelector(false)}>Volver</Button>
                    </Box>
                </DialogTitle>
                <DialogContent style={{ width: '100%' }} dividers>
                    <PatientLoginForm buttonText="Buscar" onSubmit={onSubmitPatientSelectorForm}></PatientLoginForm>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default EpisodeGallery;