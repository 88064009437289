import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { InputAdornment, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import SearchIcon from '@material-ui/icons/Search';
import api from '../api.js';

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
}

export default function AddressAutocomplete({ initialAddress, initialValue, onChange }) {
    const [inputValue, setInputValue] = useState();
    const [options, setOptions] = useState([]);

    const debounceOnChange = useCallback(
        debounce(value => {
            setInputValue(value);
            if (value) {
                (async () => {
                    let addressList = await api.post('address/getAddressAutocomplete', {
                        searchText: value
                    }) || [];

                    setOptions(addressList.map(address => ({ ...address, text: address['formatted_address'] })));
                })();
            } else { setOptions([]) }
        }, 1400),
        []
    );

    const [open, setOpen] = React.useState(false);
    const loading = open && options.length === 0 && inputValue && inputValue.length > 0;

    function handleChange(value) {
        debounceOnChange(value);
    }

    useEffect(() => {
        if (initialValue) {
            setInputValue(initialValue);
        }
    }, []);

    useEffect(() => {
        if (inputValue) { } else {
            setOptions([]);
        }
    }, [inputValue]);

    React.useEffect(() => {
        setOptions([]);
    }, [open]);

    const handleChangeSelected = selected => {
        onChange(selected);
    }


    const detaultValue = { text: initialValue };
    return (
        <div>
            <Autocomplete
                options={options}
                defaultValue={detaultValue}
                getOptionLabel={(option) => option.text}
                filterOptions={(x) => x}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                forcePopupIcon={false}
                loadingText="Buscando..."
                noOptionsText="No se encontró la dirección"
                loading={loading}
                onChange={(event, newValue) => handleChangeSelected(newValue)}
                renderInput={params =>
                    <TextField
                        {...params}
                        variant="outlined"
                        value={inputValue}
                        placeholder="Ej. Ave. Alvarez 1532, Viña del Mar, Valparaíso"
                        onChange={e => handleChange(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment:
                                <InputAdornment position="start">
                                    <LocationOnIcon color="secondary" />
                                </InputAdornment>,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                        fullWidth
                    />
                }
            />
        </div>
    );
}