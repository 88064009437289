import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ReCAPTCHA from "react-google-recaptcha";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import IdentificationDocumentDialog from "./IdentificationDocumentDialog";
import { validate, clean, format } from 'rut.js';
import QrReader from 'react-qr-reader'
import CameraIcon from '@material-ui/icons/Camera';
import { ReCaptcha } from 'react-recaptcha-v3';
import {
    Button,
    Divider,
    ListSubheader,
    Card,
    Typography,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Chip,
    DialogActions
} from '@material-ui/core';
import api from '../api.js';
import {
    useHistory,
} from "react-router-dom";

const useStyles = makeStyles(theme => ({
}));

function PatientLoginForm({ buttonText = '', onSubmit = () => { } }) {

    const classes = useStyles();
    const { register, handleSubmit, watch, errors, setError, clearError, setValue, getValues, formState, control } = useForm({
        defaultValues: {
            rut: '',
            serial: ''
        }
    });
    const { dirty, isSubmitting, touched, submitCount } = formState;
    const history = useHistory();

    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));

    const [values, setValues] = React.useState({
        rut: '',
        serial: ''
    });

    const [loading, setLoading] = useState(false);

    const onLocalSubmit = async ({ rut, serial }) => {
        try {
            setLoading(true);
            let sessionCreated = await api.post('auth/login', {
                rut: clean(rut),
                serial
            });
            sessionCreated.patient = await api.get('patient/' + sessionCreated.patient.id, { state: sessionCreated.state });
            onSubmit(sessionCreated);
            setLoading(false);
        } catch (err) {
            setShowErrorDialog(true);
            setLoading(false);
        }
    }

    const verifyCaptchaCallback = recaptchaToken => {
        console.log(recaptchaToken, "<= your recaptcha token")
    }


    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [dialogScanner, setDialogScanner] = useState(false);


    const handleScan = data => {
        if (data) {
            console.log(data);
        }
    }
    const handleError = err => {
        console.error(err)
    }

    const validateRut = rut => {
        if (!validate(rut)) {
            setError("rut", "invalidRut", "Ingrese un rut válido");
        } else {
            clearError("rut");
        }
        return rut;
    }
    const formatRut = rut => {
        if (validate(rut)) {
            setValue('rut', format(rut));
        }
    }
    const cleanRut = rut => {
        if (validate(rut)) {
            setValue('rut', clean(rut));
        }
    }

    return (
        <div>
            <Box>
                <form onSubmit={handleSubmit(onLocalSubmit)}>
                    <Controller
                        as={TextField}
                        control={control}
                        error={errors.rut}
                        fullWidth
                        variant="outlined"
                        label="Rut"
                        name="rut"
                        ref={register({
                            required: true,
                            maxLength: 14
                        })}
                        onChange={([e]) => validateRut(e.target.value)}
                        onBlur={([e]) => formatRut(e.target.value)}
                        onFocus={([e]) => cleanRut(e.target.value)}
                        helperText={errors.rut && errors.rut.message}
                        disabled={loading}
                        labelWidth={30}></Controller>

                    <br /><br />

                    <Controller
                        as={TextField}
                        error={errors.serial}
                        control={control}
                        name="serial"
                        ref={register({
                            required: true
                        })}
                        disabled={loading}
                        labelWidth={170}
                        fullWidth
                        variant="outlined"
                        helperText={errors.serial && ''}
                        label="Número de documento"></Controller>

                    <br />
                    <br />

                    <IdentificationDocumentDialog></IdentificationDocumentDialog>

                    {/* <Button onClick={() => setDialogScanner(true)}>foto </Button> */}

                    <br />
                    <br />
                    <ReCaptcha
                        sitekey="6LdzwOoUAAAAAHJtvfUmoR2g8MvuqeHhctmaaLw5"
                        action='login'
                        verifyCallback={verifyCaptchaCallback}
                    />

                    <div style={{ display: 'flex', width: '100%' }}>
                        <Button disabled={dirty} color="primary" type="submit" variant="contained" disabled={loading} style={{ display: 'flex', flex: 1 }}>
                            {!loading && (buttonText || 'Ingresar')}
                            {loading && 'Espere unos segundos...'}
                        </Button>
                    </div>
                </form>
            </Box>

            <Dialog open={dialogScanner}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <CameraIcon style={{ fontSize: 30, marginRight: 16 }} color="error"></CameraIcon>
                        <Typography variant="h6">
                            <Box fontWeight="fontWeightMedium">
                                Scanner
                            </Box>
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent dividers style={{ minHeight: 400, minWidth: 600 }}>
                    <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowErrorDialog(false)} color="primary" variant="contained">Volver</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showErrorDialog}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <ErrorOutlineIcon style={{ fontSize: 30, marginRight: 16 }} color="error"></ErrorOutlineIcon>
                        <Typography variant="h6">
                            <Box fontWeight="fontWeightMedium">
                                Ha ocurrido un error
                            </Box>
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent dividers style={{ minHeight: 90 }}>
                    <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
                        <Box fontWeight="fontWeightMedium" textAlign="center">
                            Los datos ingresados no son correctos
                        </Box>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowErrorDialog(false)} color="primary" variant="contained">Entiendo</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


export default PatientLoginForm;