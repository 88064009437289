import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Box } from '@material-ui/core';

function IdentificationDocumentDialog() {

    const [showSerialImage, setShowSerialImage] = useState(false);

    return (
        <div style={{ marginTop: 16 }}>
            <Button onClick={() => setShowSerialImage(true)} variant="outlined">
                <Typography variant="caption" small>
                    <Box fontWeight="fontWeightMedium">
                        ¿Cuál es mi número de documento?
                            </Box>
                </Typography>
            </Button>
            <Dialog open={showSerialImage}>
                <DialogTitle>
                    ¿Cuál es mi número de documento?
                </DialogTitle>
                <DialogContent dividers>
                    <img src="/SD_CI_NUEVA_VF.png" width="100%" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowSerialImage(false)} color="primary" variant="contained">Volver</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default IdentificationDocumentDialog;