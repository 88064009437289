
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import { green, red, amber } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

import {
    Typography,
    Box,
    ThemeProvider
} from '@material-ui/core';
import api from '../api.js';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        position: 'fixed'
    }
}));

function EpisodeStatus({ episode }) {
    const greenButton = createMuiTheme({
        palette: {
            primary: green,
        },
    });

    return (
        <Box display="flex" flexDirection="row" alignContent="center">
            <Box display="flex" flexDirection="row" alignContent="center" justifyContent="center">
                <ThemeProvider theme={greenButton}>
                    <Typography variant="subtitle2" component="h2" style={{ marginRight: 8 }} color="primary">
                        <Box fontWeight="fontWeightMedium" fontSize="30">
                            •
                    </Box>
                    </Typography>
                </ThemeProvider>
            </Box>
            <Typography variant="subtitle1" component="h2">
                <Box fontWeight="fontWeightMedium" style={{ textTransform: 'capitalize' }}>
                    {(episode.status || {}).name || '--'}
                </Box>
            </Typography>
        </Box>
    )
}

export default EpisodeStatus;