import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import Login from './pages/login/login.js';
import Home from './pages/home/home.js';
import Loading from './pages/loading/loading.js';
import { createMuiTheme } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import { spring, AnimatedSwitch } from 'react-router-transition';
import CssBaseline from '@material-ui/core/CssBaseline';


import ReactGA from 'react-ga';
ReactGA.initialize('UA-96143660-2');

ReactGA.pageview(window.location.pathname + window.location.search);

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#0d69b4',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    }
});

const history = createBrowserHistory();

// Animación
// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
    return {
        opacity: styles.opacity,
        transform: `scale(${styles.scale})`,
    };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
    return spring(val, {
        stiffness: 330,
        damping: 22,
    });
}

// child matches will...
const bounceTransition = {
    // start in a transparent, upscaled state
    atEnter: {
        opacity: 0,
        scale: 1.2,
    },
    // leave in a transparent, downscaled state
    atLeave: {
        opacity: bounce(0),
        scale: bounce(0.8),
    },
    // and rest at an opaque, normally-scaled state
    atActive: {
        opacity: bounce(1),
        scale: bounce(1),
    },
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Helmet>
                <title>Portal Pacientes</title>
            </Helmet>
            <Router history={history}>
                <div className="App">
                    <AnimatedSwitch
                        atEnter={bounceTransition.atEnter}
                        atLeave={bounceTransition.atLeave}
                        atActive={bounceTransition.atActive}
                        mapStyles={mapStyles}
                        className="route-wrapper"
                    >
                        <Route path="/ingresar" component={Login}>
                        </Route>
                        <Route path="/home" component={Home}>
                        </Route>
                        <Route path="/claveunica" component={Loading}>
                        </Route>
                        <Redirect from='*' to='/ingresar' />
                    </AnimatedSwitch>
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;
