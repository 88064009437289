import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import EmailIcon from '@material-ui/icons/Email';
import AddressMap from "./AddressMap";

import {
    Button,
    Divider,
    ListSubheader,
    Card,
    CardContent,
    Typography,
    Box,
    Chip
} from '@material-ui/core';
import api from '../api.js';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    patientForm: {
        '& > *': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: '100%',
        },
    },
    inline: {
        display: 'inline',
    },
    chipList: {
        display: 'flex',
        paddingLeft: '16px'
    },
    chipItem: {
        marginRight: '6px'
    },
    customSubheader: {
        display: 'flex'
    }
}));

function PatientForm({ onChange = () => { }, initialData = {} }) {

    const classes = useStyles();

    const [patient, setPatient] = React.useState({
        phone: '',
        email: '',
        address: {},
        ...initialData
    });

    const handleChangePatient = name => event => {
        setPatient({ ...patient, [name]: event.target.value });
        onChange(patient);
    };

    const onChangeAddress = addressUpdated => {
        setPatient({ ...patient, address: addressUpdated });
        onChange({ ...patient, address: addressUpdated });
    }

    return (
        <Box>
            <Typography variant="subtitle1" component="h2" style={{ marginBottom: 10 }}>
                <Box fontWeight="fontWeightMedium">
                    Mis datos de contacto
                            </Box>
            </Typography>
            <form className={classes.patientForm}>

                {/* Teléfono */}
                <TextField
                    id="outlined-adornment-amount"
                    value={patient.phone}
                    onChange={handleChangePatient('phone')}
                    labelWidth={60}
                    fullWidth
                    required
                    maxLength={8}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start">
                                <PhoneEnabledIcon />
                                <Typography
                                    style={{ marginLeft: 8 }}
                                    variant="subtitle1"
                                >
                                    <Box fontWeight="fontWeightLight">
                                        +569
                                                </Box>
                                </Typography>
                            </InputAdornment>

                    }}
                    variant="outlined"
                    label="Teléfono"
                />

                {/* <!-- Email --> */}
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="patient-email">Email</InputLabel>
                    <OutlinedInput
                        id="patient-email"
                        value={patient.email}
                        onChange={handleChangePatient('email')}
                        startAdornment={
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        }
                        labelWidth={40}
                        variant="outlined"
                    />
                </FormControl>

                <AddressMap initialAddress={patient.address} onChange={onChangeAddress}></AddressMap>
            </form>
        </Box>
    )
}


export default PatientForm;