import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    Redirect,
    useLocation,
    Link
} from "react-router-dom";
import { withRouter } from "react-router";

import { spring, AnimatedSwitch } from 'react-router-transition';

import { makeStyles } from '@material-ui/core/styles';
import HideOnScroll from "../../components/HideOnScroll.js";
import {
    Card,
    CssBaseline,
    Typography,
    CardContent,
    OutlinedInput,
    InputLabel,
    Container,
    Hidden,
    InputAdornment,
    Toolbar,
    AppBar,
    IconButton,
    FormControl,
    Button,
    TextField,
    Box
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';


import GroupIcon from '@material-ui/icons/Group';

import IdentificationDocumentDialog from "../../components/IdentificationDocumentDialog.js";

import './login.css';
import api from '../../api.js';

import LoginWelcome from "../../components/LoginWelcome";
import LoginAlternative from "../../components/LoginAlternative";

const useStyles = makeStyles(theme => ({
    content: {
        minHeight: '100vh',
        flexGrow: 1,
        padding: theme.spacing(1)
    },
    cardForm: {
        maxWidth: '600px',
        margin: 'auto'
    }
}));

// Animación
// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
    return {
        opacity: styles.opacity,
        transform: `scale(${styles.scale})`,
    };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
    return spring(val, {
        stiffness: 330,
        damping: 22,
    });
}

// child matches will...
const bounceTransition = {
    // start in a transparent, upscaled state
    atEnter: {
        opacity: 0,
        scale: 1.2,
    },
    // leave in a transparent, downscaled state
    atLeave: {
        opacity: bounce(0),
        scale: bounce(0.8),
    },
    // and rest at an opaque, normally-scaled state
    atActive: {
        opacity: bounce(1),
        scale: bounce(1),
    },
};

function Login(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));

    async function getSession() {
        try {
            let data = await api.post('auth/getSession');
            if (data && data.patient) {
                history.push('/home')
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getSession();
    }, []);

    const AnimatedSwitchUI = () => (
        <Container maxWidth="sm" flexGrow className={classes.container}>
            <Box mt={8}>
                <AnimatedSwitch
                    atEnter={bounceTransition.atEnter}
                    atLeave={bounceTransition.atLeave}
                    atActive={bounceTransition.atActive}
                    mapStyles={mapStyles}
                    className="route-wrapper"
                    location={location}
                >
                    <Route exact path="/ingresar/bienvenido" component={LoginWelcome} />
                    <Route exact path="/ingresar/formulario" component={LoginAlternative} />
                    <Redirect from='*' to='/ingresar/bienvenido' />
                </AnimatedSwitch>
            </Box>
        </Container>
    );

    const VERSIONAPP = window.VERSION || 'v1.0.12';

    return (
        <div className="Login">
            <main className={classes.content}>
                <HideOnScroll {...props}>
                    <AppBar className={classes.toolbar}>
                        <Toolbar>
                            <Box flex="1" display="flex" justifyContent="center">
                                <GroupIcon></GroupIcon>
                                <Typography variant="subtitle1" className={classes.title} align="center" style={{ marginLeft: 8 }}>
                                    <Box fontWeight="fontWeightMedium">
                                        Portal Pacientes ({VERSIONAPP})
                                </Box>
                                </Typography>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <AnimatedSwitchUI />
            </main>
        </div>
    )
}


export default Login;