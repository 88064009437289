import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Link, NavLink } from 'react-router-dom';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormLabel from '@material-ui/core/FormLabel';
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        padding: '16px'
    },
    avatarImg: {
        width: '40px',
        heiht: '40px'
    },
    userName: {
        marginTop: '10px'
    }
}));

function EncounterTimeline() {

    const classes = useStyles();

    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));

    const [commentList, setCommentList] = React.useState([{}, {}, {}]);

    return (
        <div>
            <List>
                {commentList.map((comment, index) => (
                    <ListItem key={comment.name} activeStyle={{ color: '#0d69b4' }}>
                        <ListItemText primary={(comment.createdBy || {}).fullname + ' ha comentado:'} secondary={comment.text} />
                    </ListItem>
                ))}

                {
                    (commentList.length == 0) && <p>No hay comentarios agregados</p>
                }
            </List>

        </div >
    )
}


export default EncounterTimeline;