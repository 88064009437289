import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import {
    useHistory,
    Link
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CssBaseline,
    Typography,
    CardContent,
    OutlinedInput,
    InputLabel,
    AppBar,
    Toolbar,
    InputAdornment,
    FormControl,
    Button,
    TextField,
    Box
} from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BackButton from "./BackButton";
import PatientLoginForm from "./PatientLoginForm";

import api from '../api.js';

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    cardForm: {
        maxWidth: '600px',
        margin: 'auto'
    }
}));

function LoginAlternative() {
    const classes = useStyles();
    const history = useHistory();

    const [opts, setOpts] = useState({
        response_type: 'code',
        scope: 'openid run name email',
    });

    const onSubmitPatientLogin = session => {
        localStorage.setItem("session", JSON.stringify(session));
        history.push('/home');
    };

    return (
        <Box>
            <Toolbar disableGutters={true}>
                <BackButton to={'/ingresar/bienvenido'} ></BackButton>
            </Toolbar>
            <Card className={classes.cardForm}>
                <CardContent>
                    <Typography variant="h6">
                        <Box fontWeight="fontWeightMedium">
                            Ingreso pacientes
                        </Box>
                    </Typography>
                    <Typography variant="caption">
                        <Box fontWeight="fontWeightLight">
                            Complete la información y presione ingresar
                        </Box>
                    </Typography>
                    <br />
                    <PatientLoginForm buttonText="Ingresar" onSubmit={onSubmitPatientLogin}></PatientLoginForm>
                </CardContent>
            </Card>
        </Box>
    )
}


export default LoginAlternative;