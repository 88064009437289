import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://mi.ssvq.cl/apipatient/'
    //baseURL: 'http://localhost:1337/apipatient/'
});

axiosInstance.interceptors.request.use(
    request => {
        const session = JSON.parse(localStorage.getItem("session") || '{}')
        if (!(request.params || {}).state && session && session.state) {
            request.params = { ...request.params || {}, state: session.state };
        }
        return request;
    }, error => {
        return Promise.reject({ ...error });
    }
);

axiosInstance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        if (error.response.status == 403 && false) {
            localStorage.removeItem("session");
            //window.open('https://api.claveunica.gob.cl/api/v1/accounts/app/logout', '_BLANK');
            window.location.href = '/';
        }
        error.response.data = {};
        return Promise.reject(error);
    }
);

export default axiosInstance;