import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import {
    Link
} from "react-router-dom";
import {
    Card,
    Typography,
    CardContent,
    Toolbar,
    Button,
    Box
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function BackButton(props) {
    return (
        <Button {...props} edge="start" component={Link} startIcon={<ArrowBackIcon />}>
            <Typography variant="caption">
                <Box fontWeight="fontWeightLight">
                    Volver
                        </Box>
            </Typography>
        </Button>
    )
}


export default BackButton;