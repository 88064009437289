import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { useParams } from "react-router";
import UpdateIcon from '@material-ui/icons/Update';
import SuccessIndicator from 'react-success-indicator';
import { createMuiTheme } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SendIcon from '@material-ui/icons/Send';
import { Link } from 'react-router-dom';

import {
    Button,
    Typography,
    Card,
    CardContent,
    Box,
    Divider,
    ListSubheader,
    Toolbar,
    IconButton,
    ThemeProvider
} from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PatientStatusSelector from "../components/PatientStatusSelector";
import { Helmet } from "react-helmet";
import BackButton from "./BackButton";
import SymptomSelector from "./SymptomSelector";
import PatientCard from "./PatientCard";
import EpisodeStatus from "./EpisodeStatus";
import api from '../api.js';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function EpisodeUpdateSymptoms() {

    const { id: idEpisode } = useParams();
    const [observation, setObservation] = useState({});
    const [resultData, setResultData] = useState({});
    const [session, setSession] = useState();

    const [lastObservation, setLastObservation] = useState({});
    const [episode, setEpisode] = useState({});
    const [dialogCreateFailure, setDialogCreateFailure] = useState(false);


    const [dialogObservationCreated, setDialogObservationCreated] = useState(false);

    useEffect(() => {
        async function getData() {
            try {
                let episode = await api.get('episode/' + idEpisode) || {};
                setEpisode(episode);

                let lastObservation = await api.get('observation', {
                    params: {
                        where: {
                            patient: (episode.patient || {}).id
                        },
                        limit: 1,
                        sort: 'createdAt DESC'
                    }
                }) || [];
                setLastObservation(lastObservation.pop() || {});
            } catch (err) { console.log(err) }
        }

        let session = JSON.parse(localStorage.getItem("session") || '{}');
        setSession(session);

        setObservation({
            episode: idEpisode,
            referral: (session.patient || {}).id,
        });

        getData();
    }, []);

    const updateSymptoms = async () => {
        try {
            let observationCreated = await api.post('observation', {
                ...observation,
                patient: episode.patient.id,
                privacy: 'patient',
                type: 'updateSymptom',
                resultData: {
                    ...resultData,
                    startSymptomDate: (new Date(resultData.startSymptomDate)).valueOf() ? new Intl.DateTimeFormat('en-US').format(new Date(resultData.startSymptomDate)) : undefined,
                    symptomList: (resultData.symptomList || []).filter(({ checked }) => checked)
                }
            });
            setDialogObservationCreated(true);
        } catch (err) {
            console.log(err);
            setDialogCreateFailure(true);
        }
    }

    const ToolbarView = () => (
        <Toolbar disableGutters>
            <BackButton to={'/home/episodio-covid/' + episode.id}></BackButton>
            <Box flex="1"></Box>
            <Button color="primary" variant="contained" onClick={updateSymptoms} endIcon={<SendIcon />}>
                Reportar síntomas
            </Button>
        </Toolbar>
    )

    return (
        <div>
            <Helmet>
                <title>Actualizar síntomas</title>
            </Helmet>
            <br />
            <ToolbarView />
            <Divider></Divider>
            <br />
            <Card>
                <CardContent>
                    <EpisodeStatus episode={episode}></EpisodeStatus>
                    <Divider style={{ marginBottom: 8, marginTop: 8 }}></Divider>
                    <PatientCard patient={episode.patient}></PatientCard>
                </CardContent>
            </Card>
            <br />
            <Card>
                <CardContent>
                    <Typography variant="subtitle1" component="h2">
                        <Box fontWeight="fontWeightMedium">
                            ¿Cómo te sientes respecto a la última actualización?
                        </Box>
                    </Typography>
                    <br />
                    <PatientStatusSelector onChange={status => setResultData({ ...resultData, status: status })}></PatientStatusSelector>
                    <br />
                    <Typography variant="body2" component="h2" >
                        <Box fontWeight="fontWeightLight">
                            Último reporte: {lastObservation.createdAtLegible}
                        </Box>
                    </Typography>
                </CardContent>
            </Card>

            <br />
            <br />
            {
                lastObservation &&
                <SymptomSelector lastObservation={lastObservation} onChange={formData => setResultData({ ...resultData, ...formData })}></SymptomSelector>
            }
            <br />
            <Divider></Divider>
            <ToolbarView />

            <Dialog
                open={dialogObservationCreated}
                aria-labelledby="alert-dialog-title"
                maxWidth="sm"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <SuccessIndicator color="green"></SuccessIndicator>
                    <br />
                    <Typography variant="title" component="h2" >
                        <Box fontWeight="fontWeightLight" style={{ textAlign: 'center' }}>
                            Observación ingresada exitosamente
                        </Box>
                    </Typography>
                    <br />
                    <Button onClick={() => setDialogObservationCreated(false)}
                        to={'/home/episodio-covid/' + episode.id}
                        component={Link}
                        color="primary"
                        fullWidth={true}
                        variant="contained">Aceptar</Button>
                </DialogContent>
            </Dialog>


            <Dialog open={dialogCreateFailure}
                aria-labelledby="alert-dialog-title"
                maxWidth="sm"
                aria-describedby="alert-dialog-description">

                <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <ErrorOutlineIcon style={{ fontSize: 60 }} color="error"></ErrorOutlineIcon>
                    <br />
                    <Typography variant="h6" component="h2" >
                        <Box fontWeight="fontWeightLight" style={{ textAlign: 'center' }}>
                            Lo sentimos, no se ha podido enviar la actualización de síntomas
                        </Box>
                    </Typography>
                    <br />
                    <Button color="primary"
                        fullWidth={true}
                        variant="contained"
                        onClick={() => setDialogCreateFailure(false)}>Aceptar</Button>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default EpisodeUpdateSymptoms;