import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Button,
    Divider,
    Card,
    CardContent,
    Typography
} from '@material-ui/core';
import api from '../api.js';
import EpisodeCard from "./EpisodeCard";
import EpisodeReportButton from "./EpisodeReportButton";
import EpisodeGallery from "./EpisodeGallery";

function IndicationList() {

    return (
        <div>
        </div >
    )
}

export default IndicationList;