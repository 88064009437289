import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
    CSSTransition,
    TransitionGroup
} from "react-transition-group";
import {
    ListSubheader,
    Divider,
    Box
} from '@material-ui/core';

import Container from '@material-ui/core/Container';
import './home.css';
import Drawer from '@material-ui/core/Drawer';
import { withRouter } from "react-router";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    Redirect,
    useLocation
} from "react-router-dom";
import { spring, AnimatedSwitch } from 'react-router-transition';

import Menu from '../../components/Menu.js';
import RemPatientList from '../../components/RemPatientList.js';
import RemPatientDetail from '../../components/RemPatientDetail.js';
import IndicationList from "../../components/IndicationList.js";
import MyAccount from '../../components/MyAccount.js';
import ReportObservation from "../../components/ReportObservation.js";
import ReportObservationList from "../../components/ReportObservationList.js";
import ReportObservationDetail from "../../components/ReportObservationDetail.js";
import PatientForm from "../../components/PatientForm.js";
import HomeView from "../../components/HomeView.js";
import EpisodeDetail from "../../components/EpisodeDetail.js";
import EpisodeUpdateSymptoms from "../../components/EpisodeUpdateSymptoms.js";
import ConditionSelector from "../../components/ConditionSelector.js";
import EpisodeReportForm from "../../components/EpisodeReportForm.js";
import HideOnScroll from "../../components/HideOnScroll.js";
import PatientStarterForm from "../../components/PatientStarterForm.js"
import ToolbarPatientAccountButton from "../../components/ToolbarPatientAccountButton.js";
import ExamsPatientList from '../../components/ExamsPatientList.js';
import ExamPatientDetail from '../../components/ExamPatientDetail.js';

import api from '../../api.js';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        width: '240px',
    },
    content: {
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '256px'
        }
    },
    toolbar: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '240px'
        }
    }
}));

function Home(props) {
    let history = useHistory();
    const classes = useStyles();

    const [mobileOpen, setMobileOpen] = useState(false);
    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));
    const [title, setTitle] = useState();
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    new MutationObserver(function () {
        setTitle(document.title);
    }).observe(document.querySelector('title'), { childList: true });

    // Animación
    // we need to map the `scale` prop we define below
    // to the transform style property
    function mapStyles(styles) {
        return {
            opacity: styles.opacity,
            transform: `scale(${styles.scale})`,
        };
    }

    // wrap the `spring` helper to use a bouncy config
    function bounce(val) {
        return spring(val, {
            stiffness: 330,
            damping: 22,
        });
    }

    // child matches will...
    const bounceTransition = {
        // start in a transparent, upscaled state
        atEnter: {
            opacity: 0,
            scale: 1.2,
        },
        // leave in a transparent, downscaled state
        atLeave: {
            opacity: bounce(0),
            scale: bounce(0.8),
        },
        // and rest at an opaque, normally-scaled state
        atActive: {
            opacity: bounce(1),
            scale: bounce(1),
        },
    };

    async function getSession() {
        try {
            let sessionData = await api.post('auth/getSession', { state: session.state });
            if (sessionData && sessionData.patient) {
                setSession(sessionData);
            } else { throw new Error('Sesión inválida'); }
        } catch (err) {
            console.log(err);
            history.push('/ingresar');
        }
    }

    useEffect(() => {
        getSession();
    }, []);

    let location = useLocation();

    useEffect(
        () => {
            //ga.send(['pageview', location.pathname])
        },
        [location]
    )

    const AnimatedSwitchUI = () => (
        <Container maxWidth="sm" flexGrow className={classes.container}>
            <Box mt={8}>
                <AnimatedSwitch
                    atEnter={bounceTransition.atEnter}
                    atLeave={bounceTransition.atLeave}
                    atActive={bounceTransition.atActive}
                    mapStyles={mapStyles}
                    className="route-wrapper"
                    location={location}
                >
                    {/* <Route exact path="/home/episodio-covid" component={HomeView} state="Inicio" />
                    <Route exact path="/home/episodio-covid/nuevo/:hash" component={EpisodeReportForm} state="Registrar caso" />
                    <Route exact path="/home/episodio-covid/:id" component={EpisodeDetail} state="Detalle caso" />
                    <Route exact path="/home/episodio-covid/:id/actualizar" component={EpisodeUpdateSymptoms} state="Actualizar síntomas" /> */}
                    <Route exact path="/home/atenciones" component={RemPatientList} state="Atenciones SAMU" />
                    <Route path="/home/atenciones/:idRemPatient" component={RemPatientDetail} state="Detalle atención SAMU" />
                    <Route path="/home/mi-cuenta" component={MyAccount} state="Mi cuenta" />
                    <Route exact path="/home/examenes" component={ExamsPatientList} state="Examenes" />
                    <Route path="/home/examenes/:idExamPatient" component={ExamPatientDetail} state="Detalle examen" />
                    <Redirect from='*' to='/home/examenes' />
                </AnimatedSwitch>
            </Box>
        </Container>
    );

    return (
        <Box className={classes.root} display="flex" flexGrow={true}>
            <HideOnScroll {...props}>
                <AppBar className={classes.toolbar}>
                    <Toolbar>
                        <Hidden smUp implementation="css">
                            <IconButton edge="start" className={classes.menuButton} onClick={handleDrawerToggle} color="inherit" aria-label="menu">
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                        <Typography variant="h6" className={classes.title} align="left">
                            <Box fontWeight="fontWeightMedium">{title}</Box>
                        </Typography>

                        <ToolbarPatientAccountButton></ToolbarPatientAccountButton>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <nav className={classes.drawer} aria-label="mailbox folders" style={{ height: '100vh' }}>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <Menu></Menu>
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <Menu></Menu>
                    </Drawer>
                </Hidden>
            </nav>

            <main className={classes.content}>
                <AnimatedSwitchUI />
            </main>
            {
                session.patient && !session.patient.dataCompleted &&
                <PatientStarterForm patient={session.patient}></PatientStarterForm>
            }
        </Box>
    )
}


export default Home;