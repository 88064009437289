import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import UpdateIcon from '@material-ui/icons/Update';
import TimelineIcon from '@material-ui/icons/Timeline';
import DoneIcon from '@material-ui/icons/Done';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Parser from 'html-react-parser';
import { green, red, amber } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import BackButton from "./BackButton";


import {
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Button,
    Divider,
    Card,
    CardContent,
    Typography,
    Box,
    Toolbar,
    IconButton,
    Fab,
    Chip,
    ThemeProvider
} from '@material-ui/core';
import api from '../api.js';

import CommentBox from "../components/CommentBox";
import PatientCard from "./PatientCard";
import EpisodeStatus from "./EpisodeStatus";
import EncounterBox from "./EncounterBox";

import PatientStatusSelector from "../components/PatientStatusSelector";
import ArchiveItem from './ArchiveItem';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        position: 'fixed'
    }
}));

function EpisodeDetail() {
    const classes = useStyles();
    const [episode, setEpisode] = useState({});
    const [observationList, setObservationList] = useState([]);
    const { id: idEpisode } = useParams();

    const [dialogUpdateSymptoms, setDialogUpdateSymptoms] = useState(false);

    useEffect(() => {
        async function getData() {
            try {
                let episode = await api.get('episode/' + idEpisode) || {};
                setEpisode(episode);
            } catch (err) { console.log(err) }

            try {
                let observationListTmp = await api.get('observation', {
                    params: {
                        where: {
                            episode: idEpisode,
                            privacy: 'patient'
                        },
                        sort: 'createdAt DESC'
                    }
                }) || [];
                setObservationList(observationListTmp);
            } catch (err) { console.log(err) }
        }

        getData();
    }, []);

    const greenButton = createMuiTheme({
        palette: {
            primary: green,
        },
    });

    return (
        <div>
            <Helmet>
                <title>Seguimiento caso</title>
            </Helmet>
            <br />
            <Toolbar disableGutters>
                <BackButton to={'/home/episodio-covid'}></BackButton>
                <Box flex="1"></Box>
                <Button color="primary"
                    variant="contained"
                    to={'/home/episodio-covid/' + episode.id + '/actualizar'}
                    component={Link}
                    size="medium"
                    edge="start"
                    startIcon={<UpdateIcon />}
                >
                    Reportar síntomas
                </Button>
            </Toolbar>
            <Divider></Divider>
            <br />
            <Card style={{ minHeight: 173 }}>
                <CardContent>
                    <EpisodeStatus episode={episode}></EpisodeStatus>
                    <Divider style={{ marginBottom: 8, marginTop: 8 }}></Divider>
                    <PatientCard patient={episode.patient}></PatientCard>
                </CardContent>
            </Card>

            <EncounterBox></EncounterBox>

            <ListSubheader style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
                <TimelineIcon style={{ marginRight: 4 }}></TimelineIcon>
                <Typography
                    variant="subtitle1"
                >
                    <Box fontWeight="fontWeightMedium">
                        Historial de actividad
                    </Box>
                </Typography>
            </ListSubheader>
            {
                observationList.length == 0 &&
                <div style={{ height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography
                        variant="body2" component="h2"
                    >
                        <Box fontWeight="fontWeightLight">
                            No hay actividad registrada
                        </Box>
                    </Typography>
                </div>
            }

            {
                observationList.map(observation =>
                    <Card style={{ marginTop: 10, marginBottom: 10 }}>
                        <CardContent>
                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                <Box flex="1">
                                    <Typography
                                        variant="subtitle1"
                                    >
                                        <Box fontWeight="fontWeightMedium">
                                            {observation.referral && observation.referral.fullname}
                                            {observation.createdBy && observation.createdBy.fullname}
                                        </Box>
                                        <Box fontWeight="fontWeightLight">
                                            {observation.type == 'admission' && ' registró el inicio del caso'}
                                            {observation.type == 'updateSymptom' && ' registró una actualización de síntomas'}
                                            {observation.type == 'intervention' && ' registró una observación de intervención'}
                                            {observation.type == 'regulation' && ' registró una observación de regulación'}
                                            {observation.type == 'medicalAdvice' && ' registró un consejo médico'}
                                            {observation.type == 'generalAdvice' && ' registró un consejo general'}
                                        </Box>
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="body2"
                                >
                                    <Box fontWeight="fontWeightLight">
                                        {observation.createdAtLegible}
                                    </Box>
                                </Typography>
                            </Box>

                            <Divider style={{ marginTop: 8, marginBottom: 8 }}></Divider>

                            {observation.description &&

                                <Box>
                                    <Typography
                                        variant="body2"
                                        style={{ marginBottom: 4 }}
                                    >
                                        <Box fontWeight="fontWeightLight">
                                            Observación
                                </Box>
                                    </Typography>
                                    <Card>
                                        <CardContent>
                                            <Typography
                                                variant="body1"
                                            >
                                                <Box fontWeight="fontWeightMedium">
                                                    {Parser(observation.description)}
                                                </Box>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Box>
                            }

                            {
                                observation.attachments && observation.attachments.length &&
                                <Box style={{ marginTop: 8 }}>
                                    {
                                        observation.attachments.map(attachment =>
                                            (attachment && <ArchiveItem archive={attachment}></ArchiveItem>)
                                        )
                                    }
                                </Box>
                            }

                            {
                                (observation.resultData || {}).status &&
                                <Box>
                                    <Typography
                                        variant="body2"
                                        style={{ marginBottom: 8 }}
                                    >
                                        <Box fontWeight="fontWeightLight">
                                            ¿Cómo te sientes respecto a la última actualización?
                                        </Box>
                                    </Typography>
                                    <PatientStatusSelector defaultValue={(observation.resultData || {}).status}></PatientStatusSelector>
                                </Box>
                            }

                            <br />

                            <Typography
                                variant="body2"
                                style={{ marginBottom: 4 }}
                            >
                                <Box fontWeight="fontWeightLight">
                                    Síntomas
                                    </Box>
                            </Typography>
                            {
                                ((observation.resultData || {}).symptomList || []).length == 0 &&
                                <Typography
                                    variant="caption"
                                >
                                    <Box fontWeight="fontWeightLight" textAlign="center">
                                        No registra síntomas
                                    </Box>
                                </Typography>
                            }
                            {
                                ((observation.resultData || {}).symptomList || []).map((symptom, index) =>
                                    <Chip
                                        key={index}
                                        variant="outlined"
                                        label={symptom.name}
                                        color={symptom.checked ? 'primary' : ''}
                                        style={{ marginBottom: 10, marginRight: 10 }}
                                    />
                                )
                            }

                            <br />
                            {
                                (observation.resultData || {}).symptomAux &&
                                <Box style={{ marginTop: 6, marginBottom: 6 }}>
                                    <Typography
                                        variant="body2"
                                        component="h2"
                                    >
                                        <Box fontWeight="fontWeightLight">
                                            ¿Presenta otro síntoma que desee reportar?
                                        </Box>
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="h2"
                                    >
                                        <Box fontWeight="fontWeightMedium">
                                            {(observation.resultData || {}).symptomAux || '--'}
                                        </Box>
                                    </Typography>
                                </Box>
                            }

                            {
                                (observation.resultData || {}).temperature &&
                                <Box style={{ marginTop: 6, marginBottom: 6 }}>
                                    <Typography
                                        variant="body2"
                                        component="h2"
                                    >
                                        <Box fontWeight="fontWeightLight">
                                            Temperatura
                                        </Box>
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="h2"
                                    >
                                        <Box fontWeight="fontWeightMedium">
                                            {(observation.resultData || {}).temperature || '--'}
                                        </Box>
                                    </Typography>
                                </Box>
                            }

                            {
                                (observation.resultData || {}).startSymptomDate &&
                                <Box style={{ marginTop: 6, marginBottom: 6 }}>
                                    <Typography
                                        variant="body2"
                                        component="h2"
                                    >
                                        <Box fontWeight="fontWeightLight">
                                            ¿Desde cuándo presenta los síntomas?
                                        </Box>
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="h2"
                                    >
                                        <Box fontWeight="fontWeightMedium">
                                            {(observation.resultData || {}).startSymptomDate || '--'}
                                        </Box>
                                    </Typography>
                                </Box>
                            }



                            {
                                (observation.resultData || {}).hasSymptomaticContact &&
                                <Box style={{ marginTop: 6, marginBottom: 6 }}>
                                    <Typography
                                        variant="body2"
                                        component="h2"
                                    >
                                        <Box fontWeight="fontWeightLight">
                                            ¿Ha estado en contacto con gente con sintomatología respiratoria?
                                        </Box>
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="h2"
                                    >
                                        <Box fontWeight="fontWeightMedium">
                                            {(observation.resultData || {}).hasSymptomaticContact || '--'}
                                        </Box>
                                    </Typography>
                                </Box>
                            }

                            {
                                (observation.resultData || {}).hasCovidContact &&
                                <Box style={{ marginTop: 6, marginBottom: 6 }}>
                                    <Typography
                                        variant="body2"
                                        component="h2"
                                    >
                                        <Box fontWeight="fontWeightLight">
                                            ¿Ha estado en contacto con gente con diagnóstico o sospecha de Coronavirus?
                                        </Box>
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="h2"
                                    >
                                        <Box fontWeight="fontWeightMedium">
                                            {(observation.resultData || {}).hasCovidContact || '--'}
                                        </Box>
                                    </Typography>
                                </Box>
                            }


                        </CardContent>
                    </Card>
                )
            }

            <br />
            <br />
        </div >
    )
}

export default EpisodeDetail;