import React, { useState, useEffect } from 'react';
import {
    Card,
    Typography,
    CardContent,
    Link,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AttachFileIcon from '@material-ui/icons/AttachFile';

import api from '../api.js';

const useStyles = makeStyles(theme => ({
    lineClamp: {
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden'
    }
}))

function ArchiveItem({ archive }) {

    const classes = useStyles();

    const [dialogArchive, setDialogArchive] = useState(false);

    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));

    const mapType = (type) => {
        if (!type) { return ''; }
        // Transforma mimetype en tipo de archivo legible
        var tempType = type.split("/").pop();
        var regex = /^.*\.(.*)$/g;
        var msType = regex.exec(tempType);
        if (msType && msType.length > 1) {
            tempType = msType[1];
        }
        type = tempType;
        // Obtener className según tipo de archivo
        var className;
        switch (type) {
            case "ms-excel": case "sheet":
                className = "excel";
                break;
            case "ms-word": case "document":
                className = "word";
                break;
            case "ms-powerpoint": case "presentation": case "slideshow":
                className = "powerpoint";
                break;
            case "pdf":
                className = "pdf";
                break;
            case "jpeg": case "jpg": case "png": case "gif":
                className = "image";
                break;
            case "mp4":
                className = "video"
                break;
            default:
                className = "archive";
        }
        return className;
    }
    const pathFile = api.defaults.baseURL + 'archive/findOne_?id=' + archive.id + '&state=' + session.state;
    const typeFile = mapType(archive.mimeType);
    return (
        <Box display="flex" flexDirection="column" flexWrap="wrap">

            <Button variant="contained" startIcon={<AttachFileIcon />} fullWidth={true} color="secondary" onClick={() => setDialogArchive(true)} style={{ marginBottom: 4, marginRight: 4 }}>
                <Typography className={classes.lineClamp} variant="body-1" fullWidth={true} >
                    {archive.name}
                </Typography>
            </Button>

            <Dialog
                open={dialogArchive}
                aria-labelledby="alert-dialog-title"
                maxWidth="lg"
                fullWidth={true}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <Box display="flex">
                        <Typography
                            variant="h6" component="h2" style={{ flex: 1 }}
                        >
                            {archive.name}
                        </Typography>
                        <Button small onClick={() => setDialogArchive(false)}>Volver</Button>
                    </Box>
                </DialogTitle>
                <DialogContent style={{ width: '100%', height: '85vh' }} dividers>
                    {
                        false && typeFile === 'pdf' &&
                        <object data={pathFile} style={{ width: '100%', height: '100%' }}
                            type="application/pdf"
                        >
                        </object>
                    }
                    {
                        typeFile === 'image' &&
                        <img src={pathFile} style={{ width: '100%' }} />
                    }

                    {
                        typeFile === 'video' &&
                        <video
                            src={pathFile}
                            controls
                            autoplay style={{ width: '100%', height: '100%' }}>
                            <source src={pathFile}
                                type="mp4" />
                        </video>
                    }

                    {
                        !['video', 'image'].includes(typeFile) &&
                        <Box display="flex" flexDirection="column" justifyContent="center">
                            <br />
                            <Typography style={{ textAlign: "center" }}>
                                {archive.name}
                            </Typography>
                            <br />
                            <Button component={Link} href={pathFile} target="_BLANK">Descargar</Button>
                        </Box>
                    }
                </DialogContent>
            </Dialog>
        </Box>

    )
}


export default ArchiveItem;