import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    Divider,
    Typography,
    Box
} from '@material-ui/core';

import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DoneIcon from '@material-ui/icons/Done';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import api from '../api.js';

const useStyles = makeStyles(theme => ({
    avatarImg: {
        width: '40px',
        heiht: '40px'
    },
}));

function ConditionSelector({ onChange = () => { } }) {

    const classes = useStyles();
    const [conditionList, setConditionList] = React.useState([]);

    const handleChange = name => event => {
        //setState({ ...state, [name]: event.target.checked });
    };

    const handleClick = (conditionClicked) => {
        let conditionListUpdated = conditionList.map(condition => ({
            ...condition,
            checked: conditionClicked.name == condition.name ? !condition.checked : condition.checked
        }));
        setConditionList(conditionListUpdated);
        onChange(conditionListUpdated.filter(({ checked }) => checked));
    };

    const [formData, setFormData] = React.useState({
        observation: '',
    });

    useEffect(() => {
        const getConditionList = async () => {
            try {
                let conditionList = await api.get('conditioncode', {
                    params: {
                        where: {
                            type: 'comorbidity',
                            fixedField: true
                        }
                    }
                }) || [];
                setConditionList(conditionList);
            } catch (err) {
                console.log(err)
            }

        }
        getConditionList();
    }, []);

    return (
        <div>
            <Typography
                variant="subtitle1"
                fontWeight="fontWeightMedium"
            >
                <Box fontWeight="fontWeightMedium">
                    ¿Presenta alguna de las siguientes enfermedades preexistentes?
                </Box>
            </Typography>

            <br />

            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap'
            }}>
                {
                    conditionList.map(condition =>
                        <Chip
                            variant="outlined"
                            label={condition.name}
                            icon={condition.checked ? <DoneIcon /> : ''}
                            onClick={() => handleClick(condition)}
                            color={condition.checked ? 'primary' : ''}
                            style={{ marginBottom: 10, marginRight: 10 }}
                        />
                    )
                }
            </div>

            <br />


            <Typography
                variant="body2"
                component="h2"
                fontWeight="fontWeightMedium"
                gutterBottom
            >
                <Box fontWeight="fontWeightLight">
                    Presione sobre la enfermedad que presenta
                    </Box>
            </Typography>


            <Divider></Divider>
            <br />


            <Typography
                variant="body1"
                fontWeight="fontWeightMedium"
            >
                <Box fontWeight="fontWeightMedium">
                    ¿Presenta otra enfermedad que desee reportar?
                    </Box>
            </Typography>
            <br />
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Otros</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    value={formData.conditionAux}
                    onChange={handleChange('serial')}
                    labelWidth={60}
                />
            </FormControl>
            <br />
        </div>
    )
}


export default ConditionSelector;