import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
    useHistory,
} from "react-router-dom";
import api from '../../api.js';


const useStyles = makeStyles(theme => ({}));

function Loading(props) {
    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));

    let history = useHistory();

    async function validateSession() {
        try {
            const searchParams = new URLSearchParams(window.location.search);
            const code = searchParams.get('code');
            const state = searchParams.get('state');

            searchParams.delete('code');
            searchParams.delete('state');

            if (code && state) {
                try {
                    let data = await api.post('auth/validateSession', { code, state });
                    setSession(data);
                    history.push('/home');
                } catch (err) {
                    console.log(err);
                    history.push('/ingresar');
                }
            } else {
                let data = await api.post('auth/getSession', { state: session.state });
                if (!data || !data.patient) { throw new Error('No autenticado'); }
            }
        } catch (err) {
            console.log(err);
            history.push('/ingresar');
        }
    }

    useEffect(() => {
        validateSession();
    }, []);

    useEffect(() => {
        if (session) {
            localStorage.setItem("session", JSON.stringify(session));
        }
    }, [session]);

    return (
        <div>
            <CssBaseline></CssBaseline>
            Cargando...
        </div>
    )
}


export default Loading;