import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Link, NavLink } from 'react-router-dom';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormLabel from '@material-ui/core/FormLabel';
import EncounterTimeline from "./EncounterTimeline";
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        padding: '16px'
    },
    avatarImg: {
        width: '40px',
        heiht: '40px'
    },
    userName: {
        marginTop: '10px'
    }
}));

function Menu() {

    const classes = useStyles();

    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));

    const [commentList, setCommentList] = React.useState([]);
    const [comment, setComment] = React.useState({});

    const handleChange = name => event => {
        setComment(event.target.value);
    };

    return (
        <div>
            <List>
                <ListItem key={comment.name} activeStyle={{ color: '#0d69b4' }}>
                    <Avatar>
                        <img src="/profile-avatar.png" className={classes.avatarImg} />
                    </Avatar>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-amount">Comentario</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            value={comment.text}
                            onChange={handleChange('comment')}
                            labelWidth={60}
                        />
                    </FormControl>
                </ListItem>
            </List>
            <Divider />
            <EncounterTimeline></EncounterTimeline>
        </div >
    )
}


export default Menu;