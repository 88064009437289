import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    Divider,
    Typography,
    Box,
    InputAdornment
} from '@material-ui/core';

import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DoneIcon from '@material-ui/icons/Done';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import api from '../api.js';

const useStyles = makeStyles(theme => ({
    avatarImg: {
        width: '40px',
        heiht: '40px'
    },
}));

function SymptomSelector({ lastObservation, showDateQuestion, onChange = () => { } }) {


    const classes = useStyles();

    const [symptomList, setSymptomList] = useState([]);

    const handleClick = (symptomClicked) => {
        let symptomListTmp = symptomList.map(symptom => ({
            ...symptom,
            checked: (symptomClicked.name == symptom.name ? !symptom.checked : symptom.checked)
        }));
        setSymptomList(symptomListTmp);
        setFormData({ ...formData, symptomList: symptomListTmp });
    };

    const [formData, setFormData] = React.useState({});

    const mergeLastObservationData = () => {
        if (!(lastObservation && lastObservation.id)) { return; }

        let { symptomList: lastSymptomList = [], symptomAux = '', temperature = '', startSymptomDate } = lastObservation.resultData || {};

        let symptomListTmp = symptomList.map((symptom) => ({
            ...symptom,
            checked: lastSymptomList.some(({ id }) => id == symptom.id)
        }));
        setSymptomList(symptomListTmp);

        setFormData({
            symptomList: symptomListTmp,
            symptomAux: symptomAux,
            temperature: temperature,
            startSymptomDate: startSymptomDate
        });
    }

    const getList = async () => {
        let symptomListSync = [];
        try {
            symptomListSync = await api.get('conditioncode', {
                params: {
                    where: {
                        type: 'symptom',
                        fixedField: true
                    }
                }
            });
            setSymptomList(symptomListSync || []);
        } catch (err) { console.log(err) }
        setFormData({
            ...formData,
            startSymptomDate: '',//moment(formData.startSymptomDate, 'dd-mm-yyyy').format('yyyy-mm-dd'),
            symptomList: symptomListSync
        });

        mergeLastObservationData();
    }

    useEffect(() => {
        mergeLastObservationData();
    }, [lastObservation]);

    useEffect(() => {
        getList();

        setFormData({
            symptomList: [],
            symptomAux: '',
            temperature: '',
            startSymptomDate: ''
        });

    }, []);
    useEffect(() => {
        onChange(formData);
    }, [formData]);

    return (
        <Card>
            <CardContent>
                <Typography
                    variant="subtitle1"
                >
                    <Box fontWeight="fontWeightMedium">
                        ¿Presenta alguno de los siguientes síntomas?
                    </Box>
                </Typography>

                <br />

                <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
                    {
                        symptomList.map(symptom =>
                            <Chip
                                key={symptom.id}
                                variant="outlined"
                                label={symptom.name}
                                icon={(symptom.checked && <DoneIcon />) || null}
                                onClick={() => handleClick(symptom)}
                                color={symptom.checked ? 'primary' : 'default'}
                                style={{ marginBottom: 10, marginRight: 10 }}
                            />
                        )
                    }
                </Box>

                <br />


                <Typography
                    variant="body2"
                    component="h2"
                    fontWeight="fontWeightMedium"
                    gutterBottom
                >
                    <Box fontWeight="fontWeightLight">
                        Presione sobre el síntoma que presenta
                    </Box>
                </Typography>


                <Divider></Divider>
                <br />

                <Typography
                    variant="body1" component="h2"
                >
                    <Box fontWeight="fontWeightMedium">
                        ¿Presenta otro síntoma que desee reportar?
                    </Box>
                </Typography>
                <br />
                <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                        defaultValue={formData.symptomAux}
                        value={formData.symptomAux}
                        onChange={event => setFormData({ ...formData, symptomAux: event.target.value })}
                    />
                </FormControl>

                <br />
                <br />

                <Typography
                    variant="subtitle1"
                    component="h2"
                >
                    <Box fontWeight="fontWeightMedium">
                        Si tiene como medirse la temperatura indique el valor
                    </Box>
                </Typography>
                <br />

                <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                        /* endAdornment={
                            <InputAdornment position="end">
                                <MyLocationIcon />
                            </InputAdornment>
                        }
                        startAdornment={
                            <InputAdornment position="start">
                                <RoomIcon />
                            </InputAdornment>
                        } */
                        defaultValue={formData.temperature}
                        value={formData.temperature}
                        onChange={event => setFormData({ ...formData, temperature: event.target.value })}
                    />
                </FormControl>
                <br />
                <br />

                {
                    showDateQuestion && (
                        <Box>
                            <Typography
                                variant="body1"
                                component="h2"
                            >
                                <Box fontWeight="fontWeightMedium">
                                    ¿Desde cuándo presenta los síntomas?
                                </Box>
                            </Typography>
                            <br />
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    type="date"
                                    value={formData.startSymptomDate}
                                    onChange={event => setFormData({ ...formData, startSymptomDate: event.target.value })}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Box>
                    )
                }
            </CardContent>
        </Card>
    )
}


export default SymptomSelector;