import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import moment from 'moment';

import {
    ListItemText,
    ListSubheader,
    Divider,
    Card,
    CardContent,
    Typography,
    Box
} from '@material-ui/core';
import api from '../api.js';

function ExamsPatientList() {

    const [examsPatientList, setExamsPatientList] = useState([]);

    useEffect(() => {
        const getList = async () => {
            try {
                let data = await api.post('auth/getExamPatientList')
                setExamsPatientList(data);
            } catch (err) { console.log(err) }
        }

        getList();
    }, []);

    return (
        <div>
            <Helmet>
                <title>Examenes</title>
            </Helmet>
            <List>
                {
                    !examsPatientList.length &&
                    <Typography
                        variant="subtitle1"
                    >
                        <Box fontWeight="fontWeightLight" textAlign="center">
                            No tienes registro de examenes
                        </Box>
                    </Typography>
                }
                {
                    (examsPatientList || []).map((examPatient, index) =>
                        <Card key={index} style={{ marginBottom: '6px' }}>
                            <ListItem button component={Link} to={`/home/examenes/${examPatient.uuid}`} alignItems="flex-start">
                                <ListItemText
                                    primary={examPatient.establishment.name}
                                    secondary={`Número de orden: ${examPatient.orderNumber}`}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    secondary={`Fecha resultado: ${moment(examPatient.dateResult).format('DD-MM-YYYY')}`}
                                    style={{display: 'flex', justifyContent: 'flex-end'}}
                                />
                            </ListItem>
                        </Card>
                    )
                }
            </List>
        </div>
    )

}

export default ExamsPatientList;