import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Divider,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Card,
    TextField,
    Fab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';


import MyLocationIcon from '@material-ui/icons/MyLocation';
import api from '../api.js';
import AddressAutocomplete from "./AddressAutocomplete";
import MapIcon from '@material-ui/icons/Map';
const useStyles = makeStyles((theme) => ({
    fab: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        position: 'absolute',
        zIndex: 99999
    }
}));


function AddressMap({
    patient = {},
    initialAddress = {},
    onChange = () => { },
    opts = { label: '¿Dónde se encuentra el paciente?' }
}) {

    const [selectAddressPatient, setSelectAddressPatient] = useState(false);
    const [address, setAddress] = React.useState();
    const [position, setPosition] = React.useState();

    const [communeList, setCommuneList] = React.useState([]);

    const classes = useStyles();

    const getLocation = async () => {
        try {
            let positionSuccess = await new Promise((res, rej) => navigator.geolocation.getCurrentPosition(res, rej));
            let { latitude, longitude } = positionSuccess.coords;
            setPosition([latitude, longitude]);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        onChange(address);
    }, [address]);

    useEffect(() => {
        const getCommuneList = async () => {
            let communeList = await api.get('commune', {
                params: {
                    where: { deleted: false },
                    limit: 400
                }
            }) || [];
            setCommuneList(communeList);
        }
        getCommuneList();

        if (initialAddress) {
            setAddress({ ...initialAddress });
            if (initialAddress && initialAddress.position) {
                let coordinates = initialAddress.position.coordinates.reverse();
                setPosition(coordinates);
            }
        }

        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: iconRetinaUrl,
            iconUrl: iconUrl,
            shadowUrl: shadowUrl
        });
    }, []);

    const handleDoubleClick = (point) => {
        setPosition(point.latlng);
    }

    const onChangeAddressText = (addressUpdated) => {
        if (!addressUpdated) {
            return setAddress({
                ...address,
                text: ''
            })
        }
        let communeObj = addressUpdated.address_components.find(({ types = [] }) => types.includes('administrative_area_level_3')) || {};
        let commune = communeList.find(({ name }) => name == communeObj['long_name']) || {};
        let positionUpdated;
        if (addressUpdated.geometry) {
            let { lat, lng } = addressUpdated.geometry.location;
            positionUpdated = { lat, lng };
            setPosition(positionUpdated);
        }
        setAddress({
            ...address,
            text: addressUpdated['formatted_address'],
            commune: commune.id,
            position: positionUpdated ? {
                type: 'Point',
                coordinates: [positionUpdated.lng, positionUpdated.lat]
            } : undefined
        });
    }

    return (
        <Box>
            <Typography
                variant="subtitle1"
                style={{ marginBottom: 16 }}
            >
                <Box fontWeight="fontWeightMedium">
                    {opts.label}
                </Box>
            </Typography>

            {address &&
                <Box>
                    <AddressAutocomplete style={{ width: '100%' }}
                        initialValue={address.text}
                        onChange={onChangeAddressText}></AddressAutocomplete>
                    < br />
                    <TextField
                        variant="outlined"
                        label="Referencia"
                        value={address.reference}
                        helperText="Ej. Block 4 Depto 35"
                        style={{ marginBottom: 16 }}
                        onChange={e => setAddress({ ...address, reference: e.target.value })}
                        fullWidth
                    />
                </Box>
            }


            <Card style={{ height: 200, width: '100%', position: 'relative' }}>

                {
                    position &&
                    <Map center={position} zoom={18} style={{ height: 200, width: '100%' }} ondblclick={handleDoubleClick}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        />
                        <Marker position={position}>
                            <Popup>Dirección del paciente</Popup>
                        </Marker>
                    </Map>
                }
                {
                    !position &&
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" style={{ backgroundColor: '#ebebeb' }}>
                        <Typography
                            style={{ margin: 16, marginTop: 0 }}
                            variant="body2"
                        >
                            <Box fontWeight="fontWeightLight" textAlign="center">
                                Agregar una dirección desde el buscador o usar mi ubicación gps
                            </Box>
                        </Typography>
                        <MapIcon fontSize="large" color="grey"></MapIcon>
                    </Box>
                }
                <Fab color="secondary" size="small" onClick={getLocation} className={classes.fab}>
                    <MyLocationIcon />
                    {/*                     <Typography
                        variant="caption"
                    >
                        <Box fontWeight="fontWeightMedium">
                            Seleccionar mi ubicación actual
                        </Box>
                    </Typography> */}

                </Fab>
            </Card>

        </Box>
    )
}


export default AddressMap;