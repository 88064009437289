import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, ListItemText, Divider, ListItem, List, Toolbar, Avatar } from '@material-ui/core';

import { Link, NavLink, useLocation, withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        padding: '16px'
    },
    avatarImg: {
        width: '40px',
        heiht: '40px'
    },
    userName: {
        marginTop: '10px'
    }
}));


function Menu() {

    const classes = useStyles();
    const location = useLocation();

    const menu = [
        // {
        //     name: 'Covid-19',
        //     path: '/episodio-covid'
        // }, 
        {
            name: 'Mis atenciones SAMU',
            path: '/atenciones'
        }, 
        {
            name: 'Mi cuenta',
            path: '/mi-cuenta'
        }, 
        {
            name: 'Mis examenes',
            path: '/examenes'
        }
    ]

    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));

    const MenuAnimated = () => (
        <List>
            {
                menu.map((route, index) => (
                    <ListItem button key={route.name} component={NavLink} to={'/home' + route.path} activeStyle={{ color: '#0d69b4', fontWeight: 'bold' }} selected={location.pathname == '/home' + route.path}>
                        <ListItemText primary={
                            <Typography variant="subtitle1">
                                <Box fontWeight="fontWeightMedium">{route.name}</Box>
                            </Typography>} />
                    </ListItem>
                ))
            }
        </List>
    )

    return (
        <Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ marginTop: 12, marginBottom: 4 }}>
                <img src="/logo.jpg" height="100" />
                <Box style={{ marginTop: 8 }}>
                    <Typography variant="h6" align="center" color="primary">
                        <Box fontWeight="fontWeightMedium">Servicio de Salud</Box>
                    </Typography>
                    <Typography variant="subtitle1" align="center">
                        <Box fontWeight="fontWeightLight">Viña del Mar - Quillota</Box>
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <MenuAnimated />
        </Box>
    )
}


export default Menu;