import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SweetAlert from 'sweetalert-react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import SendIcon from '@material-ui/icons/Send';
import {
    Card,
    CardContent,
    ListSubheader,
    Divider,
    Box,
    Typography,
    IconButton,
    DialogTitle,
    Toolbar
} from '@material-ui/core';
import {
    useHistory,
    useParams
} from "react-router-dom";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SuccessIndicator from 'react-success-indicator';
import { Helmet } from "react-helmet";
import BackButton from "./BackButton";


import CommentIcon from '@material-ui/icons/Comment';

import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from "@material-ui/core/Button";
import PatientLoginForm from "./PatientLoginForm";
import AddressMap from "./AddressMap";
import ConditionSelector from "./ConditionSelector";
import api from '../api.js';

import SymptomSelector from "./SymptomSelector";
import PatientCard from "./PatientCard";

const useStyles = makeStyles(theme => ({
    avatarImg: {
        width: '40px',
        heiht: '40px'
    },
}));

function EpisodeReportForm() {

    const classes = useStyles();
    const history = useHistory();
    const params = useParams();

    const [dialogPatient, setDialogPatient] = useState(false);

    const [session, setSession] = useState({});
    const [episode, setEpisode] = useState({});
    const [resultData, setResultData] = useState({});

    const createEpisode = async () => {
        try {
            let episodeCreated = await api.post('episode', {
                ...episode,
                address: {
                    ...episode.address,
                    district: (episode.address || {}).commune,
                },
                commune: (episode.address || {}).commune,
                patient: (episode.patient || {}).id,
                referral: (session.patient || {}).id,
                status: (statusList[0] || {}).id
            });

            if (!episodeCreated) { throw new Error('No se ha creado el episodio') }
            setEpisodeCreated(episodeCreated);

            let observationCreated = await api.post('observation', {
                type: 'admission',
                privacy: 'patient',
                episode: episodeCreated.id,
                patient: (episode.patient || {}).id,
                referral: (session.patient || {}).id,
                resultData: {
                    ...resultData,
                    startSymptomDate: resultData.startSymptomDate ? new Intl.DateTimeFormat('es-CL').format(new Date(resultData.startSymptomDate)) : undefined,
                    symptomList: (resultData.symptomList || []).filter(({ checked }) => checked)
                }
            });

            if (episode.patient) {
                await api.put('patient/' + episode.patient.id, {
                    address: {
                        ...episode.address,
                        district: (episode.address || {}).commune,
                    }
                });

                await handleUpdateCondition();
            }
            setDialogCreateSuccess(true);
        } catch (err) {
            console.log(err);
            setDialogCreateFailure(true);
        }
    };

    const [initialAddress, setInitialAddress] = useState();
    const getInitialAddress = async () => {
        try {
            if (!episode.patient.address) { throw new Error('Paciente no tiene dirección registrada') }

            let initialAddressFinded = await api.get('address/' + episode.patient.address.id) || {};
            setInitialAddress(initialAddressFinded);
        } catch (err) {
            console.log(err);
            setInitialAddress({});
        }
    }

    const validatePatientToken = async (state) => {
        try {
            let { patient } = await api.post('auth/getSession', { state: state }) || {};
            if (!patient) { throw new Error('Sesión expirada'); }

            setEpisode({
                patient: patient || {}
            });
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        let session = JSON.parse(localStorage.getItem("session") || '{}');
        setSession(session);

        if (params.hash) {
            (async () => {
                await validatePatientToken(params.hash);
                getInitialAddress();
            })();
        }

        getStatusList();
    }, []);

    const [episodeCreated, setEpisodeCreated] = React.useState({});
    const [address, setAddress] = React.useState({});
    const [dialogCreateSuccess, setDialogCreateSuccess] = React.useState(false);

    const [statusList, setStatusList] = React.useState([]);
    const getStatusList = async () => {
        let statusList = await api.get('status', {
            params: {
                where: {
                    module: 'episode'
                },
                sort: 'order ASC'
            }
        }) || [];
        setStatusList(statusList);
    }

    const onSubmitPatientForm = ({ patient, state }) => {
        //setDialogPatient(false);
        history.push('/home/episodio-covid/nuevo/' + state);
    };

    const [dialogCreateFailure, setDialogCreateFailure] = useState(false);

    const ToolbarView = () => (
        <Toolbar disableGutters>
            <BackButton to={'/home/episodio-covid'}></BackButton>
            <Box flex="1"></Box>
            <Button color="primary" variant="contained" style={{ marginLeft: 8 }} endIcon={<SendIcon />} onClick={createEpisode}>
                Reportar caso
            </Button>
        </Toolbar>
    )

    const [conditionTemporalList, setConditionTemporalList] = useState([]);

    const handleUpdateCondition = async () => {
        for (let conditionItem of conditionTemporalList) {
            try {
                let finded = await api.get('condition', {
                    params: {
                        where: {
                            patient: (episode.patient || {}).id,
                            code: conditionItem.id
                        }
                    }
                });
                if (!finded.length) {
                    await api.post('condition', {
                        patient: (episode.patient || {}).id,
                        code: conditionItem.id
                    });
                }
            } catch (err) {
                console.log(err)
            }
        }
    }

    return (
        <Box display="flex" flex="1" flexDirection="column">
            <Helmet>
                <title>Reporte de caso</title>
            </Helmet>
            <ToolbarView />
            <Divider></Divider>
            <br />
            <Card style={{ minHeight: 128 }}>
                <CardContent>
                    <PatientCard patient={episode.patient}></PatientCard>
                </CardContent>
            </Card>
            <br />

            <Card>
                <CardContent>
                    <ConditionSelector onChange={list => setConditionTemporalList(list)}></ConditionSelector>
                </CardContent>
            </Card>

            <br />

            <Card style={{ minHeight: 454 }}>
                <CardContent>
                    {
                        initialAddress &&
                        <AddressMap
                            initialAddress={initialAddress}
                            patient={episode.patient}
                            onChange={address => setEpisode({ ...episode, address: address })}></AddressMap>
                    }
                </CardContent>
            </Card>
            <br />

            <SymptomSelector showDateQuestion={true} onChange={formData => setResultData({ ...resultData, ...formData })}></SymptomSelector>

            <br />

            <Card>
                <CardContent>
                    <Typography
                        variant="subtitle1"
                        fontWeight="fontWeightMedium"
                    >
                        <Box fontWeight="fontWeightMedium">
                            ¿Ha estado en contacto con gente con sintomatología respiratoria?
                        </Box>
                    </Typography>
                    <br />
                    <div style={{
                        display: 'flex'
                    }}>
                        <ButtonGroup color="primary" aria-label="outlined primary button group" style={{
                            display: 'flex',
                            flex: 1
                        }}>
                            <Button style={{ flex: 1 }}
                                variant={resultData.hasSymptomaticContact == 'si' ? 'contained' : 'outlined'}
                                onClick={() => setResultData({ ...resultData, hasSymptomaticContact: 'si' })}>
                                Si
                            </Button>
                            <Button style={{ flex: 1 }}
                                variant={resultData.hasSymptomaticContact == 'no' ? 'contained' : 'outlined'}
                                onClick={() => setResultData({ ...resultData, hasSymptomaticContact: 'no' })}>
                                No
                            </Button>
                        </ButtonGroup>
                    </div>
                </CardContent>
            </Card>
            <br />

            <Card>
                <CardContent>
                    <Typography
                        variant="subtitle1"
                    >
                        <Box fontWeight="fontWeightMedium">
                            ¿Ha estado en contacto con gente con diagnóstico o sospecha de Coronavirus?
                            </Box>
                    </Typography>
                    <br />
                    <div style={{
                        display: 'flex'
                    }}>
                        <ButtonGroup color="primary" aria-label="outlined primary button group" style={{
                            display: 'flex',
                            flex: 1
                        }}>
                            <Button style={{ flex: 1 }}
                                variant={resultData.hasCovidContact == 'si' ? 'contained' : 'outlined'}
                                onClick={() => setResultData({ ...resultData, hasCovidContact: 'si' })}>
                                Si</Button>
                            <Button style={{ flex: 1 }}
                                variant={resultData.hasCovidContact == 'no' ? 'contained' : 'outlined'}
                                onClick={() => setResultData({ ...resultData, hasCovidContact: 'no' })}>
                                No</Button>
                        </ButtonGroup>
                    </div>
                </CardContent>
            </Card>
            <br />
            <Divider></Divider>
            <ToolbarView />

            <Dialog open={dialogCreateSuccess}
                aria-labelledby="alert-dialog-title"
                maxWidth="sm"
                aria-describedby="alert-dialog-description">

                <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <SuccessIndicator color="green"></SuccessIndicator>
                    <br />
                    <Typography variant="h6" component="h2" >
                        <Box fontWeight="fontWeightLight" style={{ textAlign: 'center' }}>
                            Reporte ingresado exitosamente
                        </Box>
                    </Typography>
                    <br />
                    <Button color="primary"
                        fullWidth={true}
                        variant="contained"
                        component={Link}
                        to={'/home/episodio-covid/' + episodeCreated.id}
                        onClick={() => setDialogCreateSuccess(false)}>Aceptar</Button>
                </DialogContent>
            </Dialog>

            <Dialog open={dialogCreateFailure}
                aria-labelledby="alert-dialog-title"
                maxWidth="sm"
                aria-describedby="alert-dialog-description">

                <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <ErrorOutlineIcon style={{ fontSize: 60 }} color="error"></ErrorOutlineIcon>
                    <br />
                    <Typography variant="h6" component="h2" >
                        <Box fontWeight="fontWeightLight" style={{ textAlign: 'center' }}>
                            Lo sentimos, no se ha podido enviar el reporte
                        </Box>
                    </Typography>
                    <br />
                    <Button color="primary"
                        fullWidth={true}
                        variant="contained"
                        onClick={() => setDialogCreateFailure(false)}>Aceptar</Button>
                </DialogContent>

            </Dialog>

            <Dialog
                open={dialogPatient}
                aria-labelledby="alert-dialog-title"
                maxWidth="sm"
                fullWidth={true}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <Box display="flex">
                        <Typography
                            variant="h6" component="h2" style={{ flex: 1 }}
                        >
                            Seleccionar paciente
                        </Typography>
                        <Button small onClick={() => setDialogPatient(false)}>Volver</Button>
                    </Box>
                </DialogTitle>
                <DialogContent style={{ width: '100%' }} dividers>
                    <PatientLoginForm buttonText="Seleccionar paciente" onSubmit={onSubmitPatientForm}></PatientLoginForm>
                </DialogContent>
            </Dialog>
        </Box >
    )
}


export default EpisodeReportForm;