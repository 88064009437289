import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { createMuiTheme } from '@material-ui/core/styles';
import { green, red, amber } from '@material-ui/core/colors';
import {
    Button,
    ThemeProvider
} from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function EpisodeUpdateSymptoms({ defaultValue = 'same', onChange = () => { } }) {

    const [status, setStatus] = useState('');

    const greenButton = createMuiTheme({
        palette: {
            primary: {
                main: '#388e3c'
            },
        },
    });

    const redButton = createMuiTheme({
        palette: {
            primary: red,
        },
    });
    const amberButton = createMuiTheme({
        palette: {
            primary: {
                main: '#757575'
            },
        },
    });

    useEffect(() => {
        onChange(status);
    }, [status]);

    useEffect(() => {
        setStatus(defaultValue);
    }, []);



    return (
        <ButtonGroup color="primary" aria-label="outlined primary button group" style={{
            display: 'flex',
            flex: 1
        }}>
            <ThemeProvider theme={redButton}>
                <Button style={{ flex: 1, marginRight: 4 }} color="primary" variant={status == 'worst' ? 'contained' : 'outlined'} onClick={() => setStatus('worst')}>
                    <Icon>sentiment_very_dissatisfied</Icon>
                    <span style={{ marginLeft: 10 }}>Peor</span>
                </Button>
            </ThemeProvider>
            <ThemeProvider theme={amberButton}>
                <Button style={{ flex: 1, marginRight: 4 }} color="primary" variant={status == 'same' ? 'contained' : 'outlined'} onClick={() => setStatus('same')}>
                    <Icon>sentiment_dissatisfied</Icon>
                    <span style={{ marginLeft: 10 }}>Igual</span>
                </Button>
            </ThemeProvider>
            <ThemeProvider theme={greenButton}>
                <Button style={{ flex: 1 }} color="primary" variant={status == 'best' ? 'contained' : 'outlined'} onClick={() => setStatus('best')}>
                    <Icon>sentiment_very_satisfied</Icon>
                    <span style={{ marginLeft: 10 }}>Mejor</span>
                </Button>
            </ThemeProvider>
        </ButtonGroup>
    )
}

export default EpisodeUpdateSymptoms;