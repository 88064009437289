import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Helmet } from "react-helmet";
import {
    Button,
    Divider,
    ListSubheader,
    Card,
    Typography,
    Box,
    Chip
} from '@material-ui/core';
import api from '../api.js';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    chipList: {
        display: 'flex',
        paddingLeft: '16px'
    },
    chipItem: {
        marginRight: '6px'
    },
    customSubheader: {
        display: 'flex'
    }
}));

function RemPatientDetail() {

    const classes = useStyles();
    const [remPatient, setRemPatient] = useState({});
    const { idRemPatient } = useParams();

    useEffect(() => {
        api.post('auth/getRemPatientDetail', { idRemPatient }).then(data => {
            setRemPatient(data);
        }, err => {
            console.log(err)
        });
    }, [idRemPatient]);

    return (
        <div>
            <Helmet>
                <title>Detalle atención SAMU</title>
            </Helmet>
            <Box display="flex">
                <Box flexGrow={1}>
                    <ListSubheader color="primary" align="left">Detalle atención</ListSubheader>
                </Box>
                <Button color="primary" href="/home/atenciones">
                    Volver
                </Button>
            </Box>
            <Divider></Divider>
            <br />
            {remPatient.rem ?
                <div>
                    <Card>
                        <List className={classes.root}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary="Fecha"
                                    secondary={`${remPatient.createdAt}`}
                                />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary="Motivo"
                                    secondary={`${remPatient.rem.callReason.name} - ${remPatient.rem.subCallReason.name}`}
                                />
                            </ListItem>
                            {
                                remPatient.rem.callReason.id == 6 ? <p></p>
                                    : <p></p>
                            }
                            {remPatient.rem.originAddress ?
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Ubicación origen"
                                        secondary={`${(remPatient.rem.originAddress.district || {}).name} - ${(remPatient.rem.originAddress.state || {}).name}`}
                                    />
                                </ListItem>
                                : ''}

                            {remPatient.rem.originPlace ?
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Ubicación origen"
                                        secondary={`${remPatient.rem.originPlace.establishmentText || remPatient.rem.originPlace.establishment.name}`}
                                    />
                                </ListItem>
                                : ''}

                            {remPatient.rem.destinationAddress ?
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Ubicación destino"
                                        secondary={`${(remPatient.rem.destinationAddress.district || {}).name} - ${(remPatient.rem.destinationAddress.state || {}).name}`}
                                    />
                                </ListItem>
                                : ''}

                            {remPatient.rem.destinationPlace ?
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Ubicación destino"
                                        secondary={`${remPatient.rem.destinationPlace.establishmentText || remPatient.rem.destinationPlace.establishment.name}`}
                                    />
                                </ListItem>
                                : ''}

                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary="Solicitante"
                                    secondary={remPatient.rem.applicantName}
                                />
                            </ListItem>
                        </List>
                    </Card>

                    <br />

                    {remPatient.hasIntervention ?
                        <Card>
                            <List className={classes.root}>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Descripción intervención"
                                        secondary={`${remPatient.descriptionIntervention}`}
                                    />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Diagnóstico"
                                        secondary={`${remPatient.diagnosticIntervention}`}
                                    />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Tratamiento farmacológico"
                                    />
                                </ListItem>
                                <div className={classes.chipList}>
                                    {remPatient.remPatientMedicinesList.map((remPatientMedicines, index) =>
                                        <Chip key={index} className={classes.chipItem}
                                            color="primary" variant="outlined"
                                            label={`${remPatientMedicines.medicine.name} - ${remPatientMedicines.medicine.presentation} (${remPatientMedicines.quantity})`} />
                                    )}
                                </div>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Prestaciones"
                                    />
                                </ListItem>
                                <div className={classes.chipList}>
                                    {remPatient.attentionProvided.map((attentionProvidedItem, index) =>
                                        <Chip key={index} className={classes.chipItem}
                                            color="primary" variant="outlined" label={attentionProvidedItem.attentionItem.name} />
                                    )}
                                </div>

                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Signos vitales"
                                    />
                                </ListItem>
                                <div className={classes.chipList}>
                                    {remPatient.vitalSignsList.map((vitalSignItem, index) =>
                                        <Chip key={index} className={classes.chipItem}
                                            color="primary" variant="outlined" label={vitalSignItem.value} />
                                    )}
                                </div>
                            </List>
                            <br />
                        </Card> :
                        <Card style={{ padding: '10px' }}>
                            <Typography variant="caption">
                                Sin intervención
                            </Typography>
                        </Card>
                    }
                </div> : ''
            }
        </div>
    )
}


export default RemPatientDetail;