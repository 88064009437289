import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

import {
    ListItemText,
    ListSubheader,
    Divider,
    Card,
    CardContent,
    Typography,
    Box
} from '@material-ui/core';
import api from '../api.js';

function RemPatientList() {

    const [remPatientList, setRemPatientList] = useState([]);

    useEffect(() => {
        const getList = async () => {
            try {
                let data = await api.post('auth/getRemPatientList')
                setRemPatientList(data);
            } catch (err) { console.log(err) }
        }

        getList();
    }, []);

    return (
        <div>
            <Helmet>
                <title>Atenciones SAMU</title>
            </Helmet>
            <List>
                {
                    !remPatientList.length &&
                    <Typography
                        variant="subtitle1"
                    >
                        <Box fontWeight="fontWeightLight" textAlign="center">
                            No tienes registro de atenciones
                        </Box>
                    </Typography>
                }
                {
                    (remPatientList || []).map((remPatient, index) =>
                        <Card key={index} style={{ marginBottom: '6px' }}>
                            <ListItem button component={Link} to={`/home/atenciones/${remPatient.id}`} alignItems="flex-start" >
                                <ListItemText
                                    primary={remPatient.createdAt}
                                    secondary={`${remPatient.rem.callReason.name} - ${remPatient.rem.subCallReason.name}`}
                                />
                            </ListItem>
                        </Card>
                    )
                }
            </List>

        </div>
    )
}

export default RemPatientList;