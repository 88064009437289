import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Link } from 'react-router-dom';
import {
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Button,
    Divider,
    Card,
    CardContent,
    Typography
} from '@material-ui/core';
import api from '../api.js';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        position: 'fixed'
    }
}));

function EpisodeReportButton() {
    const classes = useStyles();
    return (
        <div>
            <Fab variant="extended" color="primary" href="/home/episodio-covid/nuevo" className={classes.fab}>
                <AddBoxIcon className={classes.extendedIcon} />
                Reportar nuevo caso
            </Fab>
        </div>
    )
}

export default EpisodeReportButton;