import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import {
    useHistory,
    Link
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CssBaseline,
    Typography,
    CardContent,
    OutlinedInput,
    InputLabel,
    Hidden,
    InputAdornment,
    Toolbar,
    AppBar,
    IconButton,
    FormControl,
    Button,
    TextField,
    Box
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import GroupIcon from '@material-ui/icons/Group';

import IdentificationDocumentDialog from "../components/IdentificationDocumentDialog.js";

import api from "../api.js";

const useStyles = makeStyles(theme => ({
    content: {
        minHeight: '100vh',
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    cardForm: {
        maxWidth: '600px',
        margin: 'auto'
    }
}));

function LoginWelcome() {
    const classes = useStyles();

    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));
    const [opts, setOpts] = useState({
        response_type: 'code',
        scope: 'openid run name email',
    });

    async function getSession() {
        try {
            let data = await api.post('auth/getSession');
            if (data) {
                setSession(data);

                let { client_id, redirect_uri, state } = data;
                setOpts({
                    ...opts,
                    client_id,
                    redirect_uri,
                    state
                })
            } else {
                console.log('Ha ocurrido un error obteniendo sesión');
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getSession();
    }, []);

    useEffect(() => {
        if (session) {
            localStorage.setItem("session", JSON.stringify(session));
        }
    }, [session]);

    return (
        <Box display="flex" flexDirection="column" style={{ height: '100%' }} my={6}>
            <Box>
                <img src="../logo.jpg" height="100" />
                <Box style={{ marginTop: 8 }}>
                    <Typography variant="subtitle1" align="center">
                        <Box fontWeight="fontWeightMedium">Servicio de Salud</Box>
                    </Typography>
                    <Typography variant="subtitle2" align="center">
                        <Box fontWeight="fontWeightMedium">Viña del Mar - Quillota</Box>
                    </Typography>
                </Box>
            </Box>
            <Box alignSelf="bottom">
                <br />
                <Card className={classes.cardForm}>
                    <CardContent>
                        <Typography variant="subtitle1">
                            <Box fontWeight="fontWeightMedium">
                                Ingresar con mi ClaveÚnica
                            </Box>
                        </Typography>
                        <br />
                        {
                            opts.state ?
                                <a
                                    className="App-link"
                                    href={'https://accounts.claveunica.gob.cl/openid/authorize/?' + 'client_id=' + opts.client_id + '&response_type=' + opts.response_type + '&scope=' + opts.scope + '&redirect_uri=' + opts.redirect_uri + '&state=' + opts.state}
                                    rel="noopener noreferrer"
                                >
                                    <img src="../btn_claveunica_202px.png" />
                                </a>
                                :
                                <Typography variant="subtitle2">
                                    <Box fontWeight="fontWeightLight">
                                        Lo sentimos, en este momento el servicio no se encuentra disponible
                                    </Box>
                                </Typography>
                        }
                    </CardContent>
                </Card>
                <br />
                <Card className={classes.cardForm}>
                    <CardContent>
                        <Typography variant="subtitle1">
                            <Box fontWeight="fontWeightMedium">
                                Ingresar con mi Cédula de Identidad
                            </Box>
                        </Typography>
                        <br />
                        <Button color="primary" variant="contained" component={Link} to="/ingresar/formulario">
                            <ExitToAppIcon></ExitToAppIcon>
                            <Typography variant="subtitle1" style={{ marginLeft: 16 }}>
                                <Box fontWeight="fontWeightMedium">
                                    Cédula de Identidad
                                </Box>
                            </Typography>
                        </Button>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    )
}


export default LoginWelcome;