import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import RoomIcon from '@material-ui/icons/Room';

import {
    Button,
    Divider,
    ListSubheader,
    Card,
    CardContent,
    Typography,
    Box,
    Chip
} from '@material-ui/core';
import api from '../api.js';

const useStyles = makeStyles(theme => ({
}));

function EncounterBox() {

    const classes = useStyles();
    const [encounter, setEncounter] = useState({});
    const [session, setSession] = useState({});

    const getEncounter = async () => {
        try {
            let session = JSON.parse(localStorage.getItem("session") || '{}');
            setSession(session);
            if (!session.patient) { throw new Error('Session no tiene información de paciente') }
            let [encounter] = await api.get('encounter', {
                params: {
                    where: {
                        patient: session.patient.id,
                        status: 14
                    }
                }
            });
            if (!encounter) { return; }

            setEncounter(encounter);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getEncounter();
    }, []);

    return (
        <Box>
            {
                encounter.id &&
                (
                    <Card style={{ marginTop: 16 }}>
                        <CardContent>
                            <Typography
                                variant="subtitle1"
                            >
                                <Box fontWeight="fontWeightMedium">
                                    Visita en curso
                        </Box>
                            </Typography>

                            <Divider></Divider>
                            <br />

                            <Typography
                                variant="subtitle2"
                            >
                                <Box fontWeight="fontWeightLight">
                                    Un equipo de salud en el vehículo <strong>{(encounter.vehicle || {}).name || '--'}</strong> se dirige a tu dirección
                        </Box>
                            </Typography>

                            <br />

                            <Box display="flex" flexDirection="row" alignItems="center">
                                <RoomIcon />
                                <Typography
                                    variant="subtitle2"
                                    style={{ marginLeft: 8 }}
                                >
                                    <Box fontWeight="fontWeightMedium">
                                        {(encounter.address || {}).text || '--'}
                                    </Box>
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                )
            }
        </Box >
    )
}


export default EncounterBox;