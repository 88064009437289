import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    ListSubheader,
    Divider,
    Box,
    Typography,
    Avatar
} from '@material-ui/core';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";

import CommentBox from "../components/CommentBox";

import MapCss from "./Map.css";

import { Map, Marker, Popup, TileLayer } from 'react-leaflet';

import FormGroup from '@material-ui/core/FormGroup';

const useStyles = makeStyles(theme => ({
    patientForm: {
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    avatarImg: {
        width: '40px',
        heiht: '40px'
    },
}));

function ReportObservationDetail() {

    const classes = useStyles();

    const [position, setPosition] = React.useState([51.505, -0.09]);

    let observationList = [{
        name: 'Tos'
    }, {
        name: 'Fiebre'
    }];

    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));

    const [state, setState] = React.useState({
        tos: true,
        fiebre: false,
        otro: false,
    });

    const getLocation = async () => {
        try {
            let positionSuccess = await new Promise((res, rej) => navigator.geolocation.getCurrentPosition(res, rej));
            let { latitude, longitude } = positionSuccess.coords;
            setPosition([latitude, longitude]);
            console.log(position);
        } catch (err) {
            console.log(err);
        }
    }

    getLocation();

    const [formData, setFormData] = React.useState({
        observation: '',
    });

    const [address, setAddress] = React.useState({});

    const [patient, setPatient] = React.useState({
        phone: {
            value: '',
            valid: true
        }
    });

    const handleChangePatient = name => event => {
        setPatient({ ...patient, [name]: event.target.checked });
    };

    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };

    const { tos, fiebre, otro } = state;
    const error = [tos, fiebre, otro].filter(v => v).length !== 2;
    const map = (
        <Map center={position} zoom={18}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            <Marker position={position}>
                <Popup>A pretty CSS3 popup.<br />Easily customizable.</Popup>
            </Marker>
        </Map>
    )

    return (
        <div>
            <ListSubheader color="primary" align="left">DETALLE REPORTE</ListSubheader>
            <Divider></Divider>
            <br />
            <Card>
                <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="start" style={{ padding: '10px 0px', maxWidth: '600px' }}>

                        {/*                         <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Síntomas</FormLabel>
                            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                                <FormControlLabel value="female" control={<Radio />} label="Tos" />
                                <FormControlLabel value="male" control={<Radio />} label="Fiebre" />
                                <FormControlLabel value="other" control={<Radio />} label="Otro" />
                                <FormControlLabel
                                    value="disabled"
                                    disabled
                                    control={<Radio />}
                                    label="(Disabled option)"
                                />
                            </RadioGroup>
                        </FormControl> */}

                        <p>Datos paciente</p>

                        <form className={classes.patientForm}>

                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-amount">Rut</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    value={patient.rut}
                                    required
                                    onChange={handleChange('rut')}
                                    labelWidth={60}
                                />
                            </FormControl>

                            <TextField
                                id="outlined-adornment-amount"
                                value={patient.phone.value}
                                onChange={handleChangePatient('phone')}
                                labelWidth={60}
                                required
                                maxLength={8}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+569</InputAdornment>
                                }}
                                helperText={!patient.phone.valid && 'Invalid'}
                                valid={patient.phone.valid}
                                variant="outlined"
                                label="Teléfono"
                            />

                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    value={patient.email}
                                    onChange={handleChange('email')}
                                    labelWidth={60}
                                    variant="outlined"
                                />
                            </FormControl>

                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Marque los síntomas que padece</FormLabel>
                                <br />
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={tos} onChange={handleChange('tos')} value="tos" />}
                                        label="tos"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={fiebre} onChange={handleChange('fiebre')} value="fiebre" />}
                                        label="fiebre"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={otro} onChange={handleChange('otro')} value="otro" />
                                        }
                                        label="otro"
                                    />
                                </FormGroup>
                                <FormHelperText>--</FormHelperText>
                            </FormControl>

                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-amount">Otros</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    value={formData.observationText}
                                    onChange={handleChange('serial')}
                                    labelWidth={60}
                                />
                            </FormControl>
                        </form>

                        <p>Agrega tu dirección</p>

                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">Calle</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                value={address.street}
                                onChange={handleChange('serial')}
                                labelWidth={60}
                            />
                        </FormControl>

                        <div><br />

                        </div>

                        <Card>
                            {map}
                        </Card>

                        <CommentBox></CommentBox>

                        <Button variant="contained" color="primary">
                            Enviar reporte
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </div>
    )
}


export default ReportObservationDetail;