import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    ListSubheader,
    Divider,
    Box,
    Typography,
    Chip,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';
import { Helmet } from "react-helmet";

import PatientForm from "./PatientForm.js";
import ConditionSelector from "./ConditionSelector.js";
import api from '../api.js';

const useStyles = makeStyles(theme => ({
    avatarImg: {
        width: '40px',
        heiht: '40px'
    },
}));



function MyAccount() {

    const classes = useStyles();

    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));

    const [dialogUpdateContact, setDialogUpdateContact] = useState(false);
    const [dialogUpdateCondition, setDialogUpdateCondition] = useState(false);
    const [patientTmp, setPatientTmp] = useState({});
    const [patient, setPatient] = useState({});

    const [contactInfo, setcontactInfo] = useState({});

    const handleDialogUpdateContact = async () => {
        let { phone, email, address = {} } = patientTmp;
        if (address.id) {
            try {
                let { position, text, district } = address;
                await api.put('address/' + address.id, {
                    position, text, district
                });
            } catch (err) { console.log(err); }

            try {
                await api.put('patient/' + session.patient.id, {
                    phone,
                    email
                });
            } catch (err) { console.log(err); }
        } else {
            try {
                await api.put('patient/' + session.patient.id, {
                    phone,
                    email,
                    address
                });
            } catch (err) {
                console.log(err)
            }
        }
    }

    const [conditionList, setConditionList] = useState([]);
    const [conditionTemporalList, setConditionTemporalList] = useState([]);

    const getConditionList = async () => {
        try {
            let conditionList = await api.get('condition', {
                params: {
                    where: {
                        patient: (session.patient || {}).id
                    }
                }
            }) || [];
            setConditionList(conditionList);
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getConditionList();
        getPatient();
    }, []);

    const getPatient = async () => {
        try {
            let patientFinded = await api.get('patient/' + session.patient.id) || {};
            setPatient(patientFinded);
            setPatientTmp(patientFinded);
        } catch (err) {
            console.log(err)
        }
    }

    const handleDialogUpdateCondition = async () => {
        for (let conditionItem of conditionTemporalList) {
            try {
                let finded = await api.get('condition', {
                    params: {
                        where: {
                            patient: (session.patient || {}).id,
                            code: conditionItem.id
                        }
                    }
                });
                if (!finded.length) {
                    await api.post('condition', {
                        patient: (session.patient || {}).id,
                        code: conditionItem.id
                    });
                }
            } catch (err) {
                console.log(err)
            }
        }
        getConditionList();
    }

    const handleChangePatient = patientUpdated => {
        setPatientTmp(patientUpdated);
    }

    return (
        <Box>
            <Helmet>
                <title>Mi cuenta</title>
            </Helmet>
            <br />
            <Card>
                <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="center" style={{ padding: '10px 0px' }}>
                        <Avatar>
                            <img src="/profile-avatar.png" className={classes.avatarImg} />
                        </Avatar>
                        <br />
                        <Typography variant="h6" color="primary">
                            {((session || {}).patient || {}).fullname}
                        </Typography>
                        <Typography variant="subtitle2">
                            <Box fontWeight="fontWeightLight">
                                {((session || {}).patient || {}).estimatedYears} años - {((session || {}).patient || {}).genderLegible}
                            </Box>
                        </Typography>

                    </Box>
                </CardContent>
            </Card>

            <ListSubheader align="left" style={{ marginTop: 8, marginBottom: 8 }}>
                <Box display="flex" alignItems="center">
                    <Typography variant="subtitle1" style={{ flex: 1 }}>
                        <Box fontWeight="fontWeightMedium">
                            Preexistencias
                        </Box>
                    </Typography>
                    <Button onClick={() => setDialogUpdateCondition(true)} color="secondary" size="small">Actualizar</Button>
                </Box>
            </ListSubheader>
            {
                conditionList.length == 0 &&
                <Card>
                    <CardContent>
                        <Typography variant="body2" component="h2" style={{ flex: 1 }} >
                            <Box fontWeight="fontWeightLight" textAlign="center">
                                No tiene enfermedades preexistentes
                            </Box>
                        </Typography>
                    </CardContent>
                </Card>
            }

            {
                conditionList.length > 0 &&
                <Card>
                    <CardContent>
                        <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
                            {
                                conditionList.map(condition =>
                                    <Chip
                                        variant="outlined"
                                        label={(condition.code || {}).name || '--'}
                                        color={condition.checked ? 'primary' : ''}
                                        style={{ marginBottom: 10, marginRight: 10 }}
                                    />
                                )
                            }
                        </Box>
                    </CardContent>
                </Card>
            }


            <ListSubheader align="left" style={{ marginTop: 8, marginBottom: 8 }}>
                <Box display="flex" alignItems="center">
                    <Typography variant="subtitle1" style={{ flex: 1 }}>
                        <Box fontWeight="fontWeightMedium">
                            Contacto
                        </Box>
                    </Typography>
                    <Button onClick={() => setDialogUpdateContact(true)} color="secondary" size="small" >Actualizar</Button>
                </Box>
            </ListSubheader>

            <Card>
                <CardContent>
                    <List>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary="Teléfono"
                                secondary={patient.phone || '--'}
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary="Email"
                                secondary={patient.email || '--'}
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary="Dirección"
                                secondary={(patient.address || {}).text || '--'}
                            />
                        </ListItem>
                    </List>
                </CardContent>
            </Card>

            <Dialog
                open={dialogUpdateContact}
                aria-labelledby="alert-dialog-title"
                maxWidth="sm"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Actualizar contacto del paciente</DialogTitle>
                <DialogContent dividers>
                    {
                        dialogUpdateContact &&
                        <PatientForm initialData={patient}
                            onChange={handleChangePatient}>
                        </PatientForm>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogUpdateContact(false)} variant="outlined">
                        Cancelar
                    </Button>
                    <Button onClick={() => handleDialogUpdateContact() && setDialogUpdateContact(false)} variant="contained" color="primary">
                        Actualizar contacto
                        </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={dialogUpdateCondition}
                aria-labelledby="alert-dialog-title"
                maxWidth="sm"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <ListSubheader color="primary" style={{ marginTop: 16, marginBottom: 8 }} disableSticky={true}>
                            <Typography
                                variant="h6"
                            >
                                Actualizar preexistencias del paciente
                            </Typography>
                        </ListSubheader>
                        <Divider></Divider>
                        <br />
                        <ConditionSelector onChange={list => setConditionTemporalList(list)}></ConditionSelector>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogUpdateCondition(false)} variant="outlined">
                        Cancelar
                    </Button>
                    <Button onClick={() => handleDialogUpdateCondition() && setDialogUpdateCondition(false)} variant="contained" color="primary">
                        Actualizar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box >
    )
}


export default MyAccount;