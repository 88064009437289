import React, { useState } from 'react';
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import Break from 'react-break';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

const UIBreakpoints = {
    mobile: 0,
    phablet: 550,
    tablet: 768,
    desktop: 992,
};

function ExamPatientDetail() {
    
    //const baseURL = 'http://localhost:1337/apipatient/auth' //cambiar a prod
    const baseURL = 'https://mi.ssvq.cl/apipatient/auth';
    const { idExamPatient } = useParams();

    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));

    return (
        <div>

            <Helmet>
                <title>Detalle examen</title>
            </Helmet>

            <div>
                <Break breakpoints={UIBreakpoints}
                       query={{ method: 'is', breakpoint: 'mobile' }}>

                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                        <div style={{ height: '93vh', minWidth: '100%' }}>
                            <Viewer fileUrl={`${baseURL}/getExamPatientDetail?idExamPatient=${idExamPatient}&state=${session.state}`} />
                        </div>
                    </Worker>
                    
                </Break>

                <Break breakpoints={UIBreakpoints}
                       query={{ method: 'is', breakpoint: 'phablet' }}>

                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                        <div style={{ height: '93vh', minWidth: '100%' }}>
                            <Viewer fileUrl={`${baseURL}/getExamPatientDetail?idExamPatient=${idExamPatient}&state=${session.state}`} />
                        </div>
                    </Worker>
                    
                </Break>

                <Break breakpoints={UIBreakpoints}
                       query={{ method: 'is', breakpoint: 'tablet' }}>

                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                        <div style={{ height: '93vh', minWidth: '100%' }}>
                            <Viewer fileUrl={`${baseURL}/getExamPatientDetail?idExamPatient=${idExamPatient}&state=${session.state}`} />
                        </div>
                    </Worker>
                    
                </Break>
                
                <Break breakpoints={UIBreakpoints}
                       query={{ method: 'is', breakpoint: 'desktop' }}>

                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                        <div style={{ height: '93vh', minWidth: '100%', width: '160%', marginLeft: '-200px' }}>
                            <Viewer fileUrl={`${baseURL}/getExamPatientDetail?idExamPatient=${idExamPatient}&state=${session.state}`} />
                        </div>
                    </Worker>
                    
                </Break>
            </div>
            
            
       
        </div>
    )
}


export default ExamPatientDetail;