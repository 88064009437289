import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';
import {
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Button,
    Divider,
    Card,
    CardContent,
    Typography,
    Box,
    Chip
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

import Switch from '@material-ui/core/Switch';
import ButtonBase from '@material-ui/core/ButtonBase';
import api from '../api.js';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function PatientConditionList({ patient, episode = {} }) {
    const [conditionList, setConditionList] = useState([]);

    useEffect(() => {
        async function getData() {
            try {
                if (!patient) { throw new Error('No tiene información de paciente') }
                let conditionList = await api.get('condition', {
                    params: {
                        where: {
                            patient: patient.id
                        },
                        limit: 3,
                        sort: 'createdAt DESC'
                    }
                }) || [];
                setConditionList(conditionList);
            } catch (err) {
                console.log(err)
            }
        }

        getData();
    }, []);

    return (
        <Box display="flex" flexDirection="row" flexWrap="nowrap" overflow="auto" width="100%">
            {
                conditionList.length == 0 &&
                <Typography variant="body1">
                    <Box fontWeight="fontWeightLight">Sin enfermedades diagnosticadas</Box>
                </Typography>
            }
            {
                conditionList.map(condition =>
                    <Chip
                        variant="outlined"
                        label={(condition.code || {}).name || '--'}
                        icon={(condition || {}).checked ? <DoneIcon /> : ''}
                        color={(condition || {}).checked ? 'primary' : ''}
                        style={{ marginTop: 6, marginBottom: 10, marginRight: 10 }}
                    />
                )
            }
        </Box>

    )
}

export default PatientConditionList;