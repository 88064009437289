import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';
import {
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Button,
    Divider,
    Card,
    CardContent,
    Typography,
    Box,
    Chip
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import UpdateIcon from '@material-ui/icons/Update';


import Switch from '@material-ui/core/Switch';
import ButtonBase from '@material-ui/core/ButtonBase';
import api from '../api.js';
import PatientConditionList from "./PatientConditionList.js";
import EpisodeStatus from "./EpisodeStatus";
import EpisodeCardItem from "./EpisodeCardItem";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function EpisodeCard() {
    const classes = useStyles();

    const [session, setSession] = useState({});
    const [episode, setEpisode] = useState({});

    const getData = async () => {
        try {
            let session = JSON.parse(localStorage.getItem("session") || '{}');
            setSession(session);

            if (!session.patient) { throw new Error('Session no tiene información de paciente') }
            let episodeList = await api.get('episode', {
                params: {
                    where: {
                        patient: session.patient.id
                    },
                    limit: 1
                }
            }) || [];
            setEpisode(episodeList.pop() || {});
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
            <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
                <Box fontWeight="fontWeightLight">
                    Mi caso
                </Box>
            </Typography>

            {episode.id ?
                <EpisodeCardItem episode={episode}></EpisodeCardItem>
                :
                <Card style={{
                    height: 225,
                }}>
                    <CardContent style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <Typography
                            component="h2"
                            variant="subtitle1"
                        >
                            <Box fontWeight="fontWeightLight">
                                No tienes un caso registrado
                            </Box>
                        </Typography>
                        <br />

                        <Button variant="contained" color="primary" to={'/home/episodio-covid/nuevo/' + session.state} component={Link} className={classes.fab}>
                            <AddBoxIcon className={classes.extendedIcon} />
                            Reportar mi caso
                        </Button>
                    </CardContent>
                </Card>
            }
        </div >

    )
}

export default EpisodeCard;