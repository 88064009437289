import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
    DialogTitle
} from '@material-ui/core';

import api from '../api.js';

import PatientForm from "./PatientForm.js";
import ConditionSelector from "./ConditionSelector.js";


function PatientStarterForm({ patient: patientInitial = {} }) {
    const [dialogUpdateInfo, setDialogUpdateInfo] = useState(true);
    const [patient, setPatient] = useState(patientInitial);
    const [conditionList, setConditionList] = useState([]);

    const handleDialogUpdateInfo = async () => {
        try {
            setDialogUpdateInfo(false);
            await api.put('patient/' + patient.id, {
                ...patient,
                dataCompleted: true
            });

            await Promise.all(
                conditionList.map(
                    conditionItem => api.post('condition', {
                        patient: patient.id,
                        code: conditionItem.id
                    })
                )
            )
        } catch (err) { console.log(err) }
    };

    const handleChangePatient = patientUpdated => {
        setPatient(patientUpdated);
    }

    return (
        <div>
            <Dialog
                open={dialogUpdateInfo}
                aria-labelledby="alert-dialog-title"
                maxWidth="sm"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Actualizar información del paciente</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">

                        <PatientForm initialData={patient}
                            onChange={handleChangePatient}></PatientForm>

                        <br />

                        <ConditionSelector onChange={list => setConditionList(list || [])}></ConditionSelector>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogUpdateInfo(false)} variant="outlined">
                        Saltar
                    </Button>
                    <Button onClick={() => handleDialogUpdateInfo()} variant="contained" color="primary">
                        Actualizar información
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


export default PatientStarterForm;