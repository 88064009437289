import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Typography,
    Button,
    Avatar,
    Menu,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Hidden
} from '@material-ui/core';

import { Link, NavLink, useLocation, withRouter } from 'react-router-dom';
import api from '../api.js';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    Redirect,

} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}))

function ToolbarPatientAccountButton() {
    const classes = useStyles();

    let history = useHistory();

    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));
    const [dialogLogout, setDialogLogout] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    async function handleDialogLogoutConfirm() {
        // Cerrar dialog
        setDialogLogout(false);

        // Enviar señal logout a servidor missvq
        await api.post('auth/logout');

        // Limpiar session de localStorage
        localStorage.removeItem("session");

        // En caso de estar logueado con clave única
        try {
            fetch('https://api.claveunica.gob.cl/api/v1/accounts/app/logout');
        } catch (err) {
            console.log(err)
        }

        // Volver a login
        history.push('/ingresar/bienvenido');
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <Button onClick={handleClick} style={{ color: 'white' }}>
                <Avatar className={classes.small}>
                    <img src="/profile-avatar.png" className={classes.avatarImg} width="100%" />
                </Avatar>
                {
                    session.patient &&
                    <Hidden smDown implementation="css">
                        <Typography variant="body2" component="h2" color="primary-dark" style={{ marginLeft: 8 }}>
                            <Box fontWeight="fontWeightMedium">
                                {session.patient.fullname}
                            </Box>
                        </Typography>
                    </Hidden>
                }
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => null}>
                    {
                        session &&
                        session.patient && (
                            <Box display="flex" flexDirection="row" ﬂ>
                                <Avatar className={classes.small}>
                                    <img src="/profile-avatar.png" className={classes.avatarImg} width="100%" />
                                </Avatar>
                                <Typography variant="subtitle1" style={{ marginLeft: 8 }}>
                                    <Box fontWeight="fontWeightMedium">{session.patient.fullname}</Box>
                                </Typography>
                            </Box>
                        )
                    }
                </MenuItem>
                <MenuItem to={'/home/mi-cuenta/'} component={Link}>
                    <Typography variant="subtitle2" style={{ marginLeft: 16 }}>
                        <Box fontWeight="fontWeightLight" textAlign="center">Mi cuenta</Box>
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => setDialogLogout(true)}>
                    <Typography variant="subtitle2" style={{ marginLeft: 16 }}>
                        <Box fontWeight="fontWeightLight" textAlign="center">Salir</Box>
                    </Typography>
                </MenuItem>
            </Menu>

            <Dialog
                open={dialogLogout}
                aria-labelledby="alert-dialog-title"
                maxWidth="sm"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro de salir de la aplicación?
                        </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogLogout(false)}>
                        Mantener abierta mi sesión
                        </Button>
                    <Button onClick={handleDialogLogoutConfirm} color="primary" autoFocus>
                        Salir
                        </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}


export default ToolbarPatientAccountButton;