import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SweetAlert from 'sweetalert-react';
import {
    Card,
    CardContent,
    ListSubheader,
    Divider,
    Box,
    Typography,
} from '@material-ui/core';

import CommentIcon from '@material-ui/icons/Comment';

import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from "@material-ui/core/Button";
import PatientLoginForm from "../components/PatientLoginForm";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import api from '../api.js';
import SymptomSelector from "../components/SymptomSelector";

const useStyles = makeStyles(theme => ({
    avatarImg: {
        width: '40px',
        heiht: '40px'
    },
}));

function ReportObservation() {

    const classes = useStyles();

    const [position, setPosition] = React.useState([51.505, -0.09]);

    const [dialogPatient, setDialogPatient] = useState(false);

    const [session, setSession] = useState(JSON.parse(localStorage.getItem("session") || '{}'));

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const createEpisode = async () => {
        try {
            let episodeCreated = await api.post('episode', {
                //patient: 
            });
            console.log(episodeCreated);
            setDialogCreateSuccess(true);
        } catch (err) { console.log(err) }
    };

    const getLocation = async () => {
        try {
            let positionSuccess = await new Promise((res, rej) => navigator.geolocation.getCurrentPosition(res, rej));
            let { latitude, longitude } = positionSuccess.coords;
            setPosition([latitude, longitude]);
            console.log(position);
        } catch (err) {
            console.log(err);
        }
    }

    getLocation();

    const [formData, setFormData] = React.useState({
        observation: '',
    });

    const [address, setAddress] = React.useState({});
    const [dialogCreateSuccess, setDialogCreateSuccess] = React.useState(false);

    const [patient, setPatient] = React.useState({
        phone: {
            value: '',
            valid: true
        }
    });

    const handleDialogPatientConfirm = () => { };

    const [checked, setChecked] = React.useState([0]);

    const handleChangePatient = name => event => {
        setPatient({ ...patient, [name]: event.target.checked });
    };

    const handleChange = name => event => {
        //setState({ ...state, [name]: event.target.checked });
    };

    const map = (
        <Map center={position} zoom={18}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            <Marker position={position}>
                <Popup>A pretty CSS3 popup.<br />Easily customizable.</Popup>
            </Marker>
        </Map>
    )

    const onSubmitPatientForm = ({ patient }) => {
        console.log(patient);
        debugger;
    };

    return (
        <div>
            <Dialog
                open={dialogPatient}
                aria-labelledby="alert-dialog-title"
                maxWidth="sm"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography
                            variant="h6" component="h2"
                        >
                            Seleccionar paciente
                        </Typography>
                        <Button small onClick={() => setDialogPatient(false)}>Volver</Button>
                    </div>

                    <PatientLoginForm buttonText="Seleccionar paciente" onSubmit={onSubmitPatientForm}></PatientLoginForm>
                </DialogContent>
                {/*                 <DialogActions>
                    <Button onClick={() => setDialogPatient(false)}>
                        --
                    </Button>
                    <Button onClick={handleDialogPatientConfirm} color="primary" autoFocus>
                        Salir
                    </Button>
                </DialogActions> */}
            </Dialog>
            <br />
            <ListSubheader color="primary" style={{ marginTop: 16, marginBottom: 8 }} disableSticky={true}>
                <Typography
                    variant="h6"
                >
                    Reportar síntomas covid-19
                </Typography>
            </ListSubheader>
            <Divider></Divider>
            <br />
            <Card >
                <CardContent>
                    <Typography
                        variant="subtitle1"
                    >
                        <Box fontWeight="fontWeightMedium">
                            Datos del paciente
                        </Box>
                    </Typography>
                    <Typography
                        variant="h5"
                    >
                        <Box fontWeight="fontWeightMedium">
                            Jaime Felipe Cisternas Mutis
                        </Box>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                    >
                        <Box fontWeight="fontWeightLight">
                            30 años - Masculino
                        </Box>
                    </Typography>
                </CardContent>
                <Divider></Divider>
                <div style={{ padding: 10 }}>
                    <Typography
                        variant="subtitle1"
                    >
                        <Box fontWeight="fontWeightLight">
                            ¿Deseas reportar un familiar o contacto?
                        </Box>
                    </Typography>
                    <Button style={{ marginTop: 16 }} variant="outlined" onClick={() => setDialogPatient(true)}>Seleccionar otro paciente</Button>
                </div>
            </Card>
            <br />

            <SymptomSelector></SymptomSelector>

            <br />

            <Card>
                <CardContent>
                    <Typography
                        variant="subtitle1"
                    >
                        <Box fontWeight="fontWeightMedium">
                            Si tiene como medirse la temperatura indique el valor
                                </Box>
                    </Typography>
                    <br />

                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            value={formData.observationText}
                            onChange={handleChange('serial')}
                            labelWidth={60}
                        />
                    </FormControl>
                </CardContent>
            </Card>
            <br />

            <Card>
                <CardContent>
                    <Typography
                        variant="subtitle1"
                        fontWeight="fontWeightMedium"
                    >
                        <Box fontWeight="fontWeightMedium">
                            ¿Ha estado en contacto con gente con sintomatología respiratoria?
                            </Box>
                    </Typography>
                    <br />
                    <div style={{
                        display: 'flex'
                    }}>
                        <ButtonGroup color="primary" aria-label="outlined primary button group" style={{
                            display: 'flex',
                            flex: 1
                        }}>
                            <Button style={{ flex: 1 }}>Si</Button>
                            <Button style={{ flex: 1 }}>No</Button>
                        </ButtonGroup>
                    </div>
                </CardContent>
            </Card>
            <br />

            <Card>
                <CardContent>
                    <Typography
                        variant="subtitle1"
                    >
                        <Box fontWeight="fontWeightMedium">
                            ¿Ha estado en contacto con gente con diagnóstico o sospecha de Coronavirus?
                            </Box>
                    </Typography>
                    <br />
                    <div style={{
                        display: 'flex'
                    }}>
                        <ButtonGroup color="primary" aria-label="outlined primary button group" style={{
                            display: 'flex',
                            flex: 1
                        }}>
                            <Button style={{ flex: 1 }}>Si</Button>
                            <Button style={{ flex: 1 }}>No</Button>
                        </ButtonGroup>
                    </div>
                </CardContent>
            </Card>

            {/*                             <PatientForm></PatientForm>
                        <div><br />

                        </div>

                        <Card>
                            {map}
                        </Card>
 */}

            <br />
            <div style={{ display: 'flex', width: '100%' }}>
                <Button variant="contained" color="primary" style={{ display: 'flex', flex: 1 }} onClick={() => createEpisode()}>
                    Enviar reporte
                    </Button>
            </div>

            <br />

            <SweetAlert
                show={dialogCreateSuccess}
                title="Demo"
                text="SweetAlert in React"
                onConfirm={() => setDialogCreateSuccess(false)}
            />

            {/* <Dialog open={dialogCreateSuccess}
                aria-labelledby="alert-dialog-title"
                maxWidth="sm"
                aria-describedby="alert-dialog-description">

                <DialogContent>
                    <SweetAlert
                        show={true}
                        title="Demo"
                        text="SweetAlert in React"
                        onConfirm={() => setDialogCreateSuccess(false)}
                    />
                </DialogContent>

            </Dialog> */}
        </div >
    )
}


export default ReportObservation;